/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
@use 'sass:math';
@import "./bootstrap_custom.scss";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./_icons.scss";
@import "./themes";

// Font face
@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    src: url("../assets/fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: 'Roboto-Light';
    font-style: normal;
    src: url("../assets/fonts/Roboto-Light.ttf");
}
@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  src: url("../assets/fonts/Roboto-Bold.ttf");
}

// Colors
@each $name, $map in $informix-colors {
  @each $shade, $value in $map {
    $suffix: -#{$shade};
    @if $shade == "primary" {
      $suffix: "";
    }
    $color-key: #{$name}#{$suffix};
    .color-#{$color-key} {
      @include themify($themes) {
        color: themed('color-#{$color-key}');
       }
      // color: $value;
    }
    .bg-#{$color-key} {
      @include themify($themes) {
        background-color: themed('color-#{$color-key}') !important;
      }
      // background-color: $value;
    }
    .border-#{$color-key} {
      @include themify($themes) {
        border-color: themed('color-#{$color-key}') !important;
      }
      // border-color: $value;
    }
  }
}

.user-avatar {
    border: 3px solid #FFF;
    -webkit-border-radius: 50%;
            border-radius: 50%;
}
#toggle-sidebar{
    position: fixed;
    top: 0px;
    left: 5px;
    @include themify($themes) {
      color: themed('color-black');
    }
    //color: #FFF;
    background: none;
    font-size: 23px;
    z-index: 3;
    display: none;
}
.btn {
  margin: 0.1em;
}
.btn:focus{
    outline: 3px auto #FFF;
}
.topnav{
  height: 50px;
  border-radius: 0;
  @include themify($themes) {
    background-color: themed('color-informix-header')!important;
    border: 1px solid themed('color-top-nav');
  }
  //background-color: $informix-blue-primary !important;
  //background-color: #3c8dbc !important;
  padding : 6px;
    z-index:4;
    .text-center{
        text-align: center;
        padding-left : 0;
        cursor: pointer;
    }
    .top-right-nav{
        .dropdown-menu{
            top: 40px;
            right: -5px;
            left : auto;
            .message-preview{
                .media{
                    .media-body{
                        .media-heading{
                            font-size: 14px;
                            font-weight: bold;
                            margin-bottom : 0;
                        }
                        p{
                            margin : 0;
                        }
                        p.last{
                            font-size : 13px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            hr {
                margin-top: 1px;
                margin-bottom: 4px;
            }
        }
    }

}
li{
  a:hover{
  @include themify($themes) {
    color: themed('color-anchor-hover');
  }
}
}

.navbar.onedb-explore{
  height: 50px;
  border-radius: 0;
  border: 1px solid;
  @include themify($themes) {
    background-color: themed('color-white') !important;
    border-color: themed('color-gray-20');
    
  }
  padding : 6px;
    z-index:3;
    .text-center{
        text-align: center;
        padding-left : 0;
        cursor: pointer;
    }
    .top-right-nav{
        .dropdown-menu{
            top: 40px;
            right: -5px;
            left : auto;
            .message-preview{
                .media{
                    .media-body{
                        .media-heading{
                            font-size: 14px;
                            font-weight: bold;
                            margin-bottom : 0;
                        }
                        p{
                            margin : 0;
                        }
                        p.last{
                            font-size : 13px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            hr {
                margin-top: 1px;
                margin-bottom: 4px;
            }
        }
    }
}

a:hover{
    text-decoration: none;
}

.sidebar{
  @include themify($themes) {
    background-color: themed('color-white');
    border-right-color: themed('color-side-bar-border');
  }
  //background-color: $informix-white-primary;
  border-radius: 0;
  position: fixed;
    z-index: 3;
    top: 50px;
    left: 225px;
    width: 225px;
    margin-left: -225px;
    border-right-style: solid;
    //border-right-color: $informix-light-gray-40;
    border-width: 1px;
    border-radius: 0;
    overflow-y: auto;
    bottom: 0;
    overflow-x: hidden;
    padding-bottom: 40px;
    -webkit-transition: all 0.2s ease-in-out;
       -moz-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
         -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
    .list-group{

        .list-group-item {
            overflow: hidden;
            border: 0;
            border-radius: 0;
            padding: 0;
            align-items: stretch;
            @include themify($themes) {
              background-color: themed('color-white');
            }
            &:hover {
              @include themify($themes) {
                background-color: themed('color-gray-10-HQ');
              }
                //background: $informix-light-gray-10;
            }

            &.active {
              @include themify($themes) {
                background: linear-gradient(to right, $informix-blue-primary 0%, $informix-blue-primary 2%, themed('color-gray-10-HQ') 2%, themed('color-gray-10-HQ') 100%);
              }
              //background: linear-gradient(to right, $informix-blue-primary 0%, $informix-blue-primary 2%, $informix-light-gray-10 2%,$informix-light-gray-10 100%);
              font-family: 'Roboto-Bold';
              .btn {
                    @include themify($themes) {
                        color: themed('color-sidebar-text-active-HQ');
                    }
                    //color: $informix-gray-20;
                    font-family: 'Roboto-Bold';
                }
            }

            .btn {
                text-align: left;
                color: $informix-light-gray-70;
                border: 0;
                border-radius: 0;
                background: none;
                cursor: pointer;
                border-radius: 0%;
                font-size: 14px;
                &:hover {
                  @include themify($themes) {
                    color: themed('color-gray-70-HQ');
                  }
                  //color: $informix-gray-40
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }

                i {
                    transition: transform 0.2s ease-in-out;
                }

                &:first-child {
                    flex: 1;
                    padding: $list-group-item-padding-y $list-group-item-padding-x;

                    i {
                        margin-right: 5px;
                    }
                }
            }

            &.list-group-item-nested .btn:first-child {
                padding-left: 2 * $list-group-item-padding-x;
            }
        }
    }

    .sidebar-search {
        padding: 0 $list-group-item-padding-x;

        .search-icon {
            color: $informix-light-gray-70;
            position: absolute;
            padding-top: $input-padding-y;
            padding-left: math.div($input-padding-x, 2);
            width: 16px;
        }

        .form-control {
            color: $informix-light-gray-70;
            border-style: solid;
            border-color: $informix-light-gray-70;
            border-width: 0 0 1px 0;
            border-radius: 0;
            padding-left: $input-padding-x * 2;

            &:focus {
              @include themify($themes) {
                box-shadow: 0 0 0 0.1rem themed('color-gray-10');
              }
            }
        }
    }

    .sidebar-dropdown{
        *:focus{
            border-radius: none;
            border:none;
        }
        .panel-title{
            font-size : 1rem;
            height : 50px;
            margin-bottom:0;
            a{
                color : $informix-light-gray-70;
                text-decoration : none;
                font-weight:400;
                background: $informix-background-color;
                span{
                    position: relative;
                    display: block;
                    padding: .75rem 1.5rem;
                    padding-top:1rem;
                }
            }
            a:hover,a:focus{
                color: #fff;
                outline: none;
                outline-offset: -2px;
            }
        }
        .panel-title:hover{
            background: darken($informix-background-color, 5%);
        }
        .panel-collapse{
            border-radius :0;
            border : none;
            .panel-body{
                .list-group-item{
                    border-radius : 0;
                    background-color: $informix-background-color;
                    border: 0 solid transparent;
                    a{
                        color: $informix-light-gray-70;;
                    }
                    a:hover{
                        color:#FFF;
                    }
                }
                .list-group-item:hover{
                    background : darken($informix-background-color, 5%);
                }
            }
        }
    }
}

.bg-white {
    background: #fff;
}

.fake-background {
  @include themify($themes) {
    background: themed('color-white');
    border: 10px solid themed('color-gray-10-HQ');
  }
  position: fixed;
  top: 0;
  bottom: 0;
  left: 225px;
  right: 0;
  z-index: -1;
}

.main-container {
  margin: 50px 0 0 225px;
  padding: 0;
  position: relative;

  &.full {
    margin-left: 0;
  }
}

.chat-panel{
    .chat-dropdown{
        margin-top: -3px;
    }
    .chat{
        .left{
            img{
                margin-right: 15px;
            }
        }
        .right{
            img{
                margin-left: 15px;
            }
        }
        height: 350px;
        overflow-y: scroll;
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            margin-bottom: 10px;
            margin-right: 15px;
            padding-bottom: 5px;
            border-bottom: 1px dotted $informix-light-gray-70;
        }
    }
    .card-footer{
        input{
            padding : 3px;
        }
    }
}
/* RTL ashboard */
.sidebarPushRight{
    left: 225px !important;
    z-index:10;
}
.mainContainerPushLeft{
    margin : 55px 255px 0 0;
}
/* End */

.display-sm{
    display:none;
}
.hide-sm{
    display:block;
}

@media (min-width: 0){
    .navbar-toggleable-xs {
        display: block !important;
    }
}
@media screen and (max-width: 600px) {
    .topnav{
        .text-center{
            a{
                font-size: 20px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    #toggle-sidebar{
        display:block;
    }
    .topnav{
        .text-center{
            padding-left : 55px;
        }
    }
    .top-right-nav{
        display: none;
    }
    .navbar-brand{
        display: inline-block;
        cursor: pointer;
    }

    .show-name{
        display: block !important;
        z-index: 1;
    }
    .display-sm {
        display:block;
    }
    .hide-sm{
        display:none;
    }
    .sidebar{
        left:0;
    }
    .fake-background {
      left: 0;
    }
    .main-container{
        margin-left : 0;
    }
    .loading {
        left: 39% !important;
    }
}
.carousel {
    .carousel-control{
        cursor: pointer;
    }
    .carousel-inner {

        .carousel-item{
            img{
                width : 100%;
                max-height : 373px;
            }
        }
        .icon-chevron-left:before {
            position: absolute;
            top: 46%;
            left : 50px;
        }
        .icon-chevron-right:before {
            position: absolute;
            top: 46%;
            right : 50px;
        }
    }
}

// Region Login
.login-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background: linear-gradient(to bottom, $informix-blue-70 0%, $informix-blue-70 50%, $informix-light-gray-10 50%, $informix-light-gray-10 100%);
    text-align: center;
    color: #fff;
    padding: 3em;

    .input-panel {
        width: 400px;
        margin: 0 auto;
        background: $informix-white-primary;
    }
    .input-lg {
        height: 32px;
        width: 300px;
        background: $informix-light-gray-10;
        padding: 10px 16px;
        font-size: 18px;
        font-family: 'Roboto-Light';
        line-height: 1.3333333;
        border-radius: 0;
    }
    .input-lg:disabled {
      background-color: $informix-light-gray-50;
    }
    .input-underline {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid $informix-black-primary;
        color: $informix-gray-50;
    }
    .input-underline:focus {
        border-bottom: 2px solid $informix-black-primary;
        box-shadow: none;
    }
    .login-btn {
        margin-top: 35px;
        border-radius: 0%;
        background: $informix-blue-primary;
        font-size: 14px;
        width: 67px;
        height: 40px;
        color: $informix-white-primary;
    }
    .login-btn:hover,.login-btn:focus,.login-btn:active,.login-btn:visited {
        background: $informix-blue-60;
        outline: none;
    }
    .copyright {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 12px;
        font-family: 'Roboto-Light';
        color: $informix-gray-20;
    }

    h1 {
        font-weight: 300;
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 36px;
        small {
            color: rgba(255,255,255,0.7);
        }
    }

    .form-group {
        padding: 8px 0;
        input::-webkit-input-placeholder {
        color: $informix-light-gray-primary !important;
        }

        input:-moz-placeholder { /* Firefox 18- */
        color: $informix-light-gray-primary !important;
        }

        input::-moz-placeholder {  /* Firefox 19+ */
        color: $informix-light-gray-primary !important;
        }

        input:-ms-input-placeholder {
        color: $informix-light-gray-primary !important;
        }
    }
    .form-content {
        padding: 40px 0;
    }
}
// End region

// Region Login
.login-page-onedb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background: linear-gradient(to bottom, $informix-light-gray-10 0%, $informix-light-gray-10 50%, $informix-blue-70 50%, $informix-blue-70 100%);
  text-align: center;
  color: #fff;
  padding: 3em;

  .input-panel {
      width: 400px;
      margin: 0 auto;
      background: $informix-white-primary;
  }
  .input-lg {
      height: 32px;
      width: 300px;
      background: $informix-light-gray-10;
      padding: 10px 16px;
      font-size: 18px;
      font-family: 'Roboto-Light';
      line-height: 1.3333333;
      border-radius: 0;
  }
  .input-lg:disabled {
    background-color: $informix-light-gray-50;
  }
  .input-underline {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid $informix-black-primary;
      color: $informix-gray-50;
  }
  .input-underline:focus {
      border-bottom: 2px solid $informix-black-primary;
      box-shadow: none;
  }
  .login-btn {
      margin-top: 35px;
      border-radius: 0%;
      background: $informix-blue-primary;
      font-size: 14px;
      width: 67px;
      height: 40px;
      color: $informix-white-primary;
  }
  .login-btn:hover,.login-btn:focus,.login-btn:active,.login-btn:visited {
      background: $informix-blue-60;
      outline: none;
  }
  .copyright {
      text-align: left;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 12px;
      font-family: 'Roboto-Light';
      color: $informix-gray-20;
  }

  h1 {
      font-weight: 300;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 36px;
      small {
          color: rgba(255,255,255,0.7);
      }
  }

  .form-group {
      padding: 8px 0;
      input::-webkit-input-placeholder {
      color: $informix-light-gray-primary !important;
      }

      input:-moz-placeholder { /* Firefox 18- */
      color: $informix-light-gray-primary !important;
      }

      input::-moz-placeholder {  /* Firefox 19+ */
      color: $informix-light-gray-primary !important;
      }

      input:-ms-input-placeholder {
      color: $informix-light-gray-primary !important;
      }
  }
  .form-content {
      padding: 40px 0;
  }
}
// End region

.nested-menu {
    .list-group-item {
        cursor: pointer;
    }
    .nested {
        list-style-type: none;
    }
    ul.submenu {
        height: 0;
        overflow: hidden;
    }
    & .expand {
        ul.submenu {
            padding-left: 0;
            overflow: initial;
            list-style-type: none;
            height: auto;
            .list-group-item {
              padding-left: 40px;
            }
        }
    }
}

.spinner {
    position: fixed !important;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -55px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

form bs-tooltip-container .tooltip-inner {
  white-space: pre-wrap;
}

.errorPage {
  margin-top: 50px;
  font-size: 200%;
  text-align: center;
}

.text-monospace {
  font-family: Consolas, monospace;
}

.dropdown-item-plain {
  display: block;
  width: 100%; // For `<button>`s
  padding: 3px $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  @include themify($themes) {
    color: themed('color-input-text-HQ');
  }
  //color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background: none; // For `<button>`s
  border: 0; // For `<button>`s
}

.navbar {
    flex-direction: row;
    align-items: center;
    .navbar-brand img {
      width: 132px;
      height: 25px;
    }
    &.onedb-explore{
      .navbar-brand img {
        width: 170px;
        height: 25px;
      }
    }
}

.navbar-nav {
    flex-direction: row;
    align-items: center;

    .nav-link {
        padding-left: .5rem;
        padding-right: .5rem;
        >a{
          cursor: pointer;
        }
    }
}

.btn[disabled] {
    pointer-events: none;
    opacity: 0.5;
}

.btn.disabled {
    opacity: 0.5;
}

.btn {
    cursor: pointer;
    border-radius: 0%;
    font-size: 12px;
}
.btn-primary {
    background: $informix-blue-primary;
    color: $informix-white-primary;
    border-color: $informix-blue-primary;
}

.btn-primary:hover,.btn-primary:focus,.btn-primary:active {
     background: $informix-blue-60;
     color: $informix-white-primary;
     border-color: $informix-blue-60;
}

.btn-primary:visited {
    background: $informix-blue-70;
    color: $informix-white-primary;
    border-color: $informix-blue-70;
}

.btn-primary:disabled {
  @include themify($themes) {
    background: themed('color-blue-HQ');
    border-color: themed('color-blue-HQ');
    color: themed('color-btn-disabled-HQ');
  }
    //background: $informix-blue-primary;
    //color: $informix-white-primary;
    //border-color: $informix-blue-primary;
    opacity: 0.5;
}

.btn-secondary {
  @include themify($themes) {
    background: themed('color-white');
    color: themed('color-blue-HQ');
    border-color: themed('color-blue-HQ');
  }
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  @include themify($themes) {
    background: themed('color-white') !important;
    color: themed('color-btn-hover') !important;
    border-color: themed('color-btn-hover') !important;
  }
    //background: $informix-white-primary;
    //color: $informix-blue-60;
    //border-color: $informix-blue-60;
}

.btn-secondary:visited  {
  @include themify($themes) {
    background: themed('color-white') !important;
    color: themed('color-anchor-hover') !important;
    border-color: themed('color-anchor-hover') !important;
  }
    //background: $informix-white-primary;
    //color: $informix-blue-70;
    //border-color: $informix-blue-70;
}

.btn-secondary:disabled {
  @include themify($themes) {
    background: themed('color-white');
    color: themed('color-blue');
    border-color: themed('color-blue');
  }
    //background: $informix-white-primary;
    //color: $informix-blue-primary;
    //border-color: $informix-blue-primary;
    opacity: 0.5;
}

.btn-danger {
    background-color:$informix-red-primary;
    color: $informix-white-primary;
    border-color: $informix-red-primary;
}

.btn-danger:hover,.btn-danger:focus,.btn-danger:active,.btn-danger:visited {
    color: $informix-white-primary;
}

.btn-danger:disabled {
    color: $informix-white-primary;
    opacity: 0.5;
}

.btn-fixed-width {
  width: 75px;
}

.badge {
    border-radius: 0%;
    font-size: 8px;
}

.form-control {
    @include themify($themes) {
      background: themed('color-white');
      border-color: themed('color-input-border');
      color: themed('color-input-text');
    }
    // background: $informix-white-primary;
    border-radius: 0%;
    font-size: 12px;
}

.card {
  @include themify($themes) {
    border-color: themed('color-gray-20-HQ');
    background: themed('color-white');
  }
    //background: $informix-white-primary;
    border-radius: 0%;
}

.card-header {
  @include themify($themes) {
    background: themed('color-gray-10-HQ');
    //border-color: themed('color-gray-20');
  }
  //background: $informix-light-gray-10;
}

.card-title {
  @include themify($themes) {
    background: themed('color-white');
    color: themed('color-gray-30-HQ');
  }
    //background: $informix-white-primary;
    font-size: 14px;
    border-radius: 0%;
    //color: $informix-gray-30;

    a {
      @include themify($themes) {
        color: themed('color-blue-HQ');
      }
        //color: $informix-blue-primary;
    }
}

a:not(.btn){
  @include themify($themes) {
    color: themed('color-blue-HQ');
  }
}

.alert {
    border-radius: 0%;
    font-size: 12px;
    background-color: $informix-white-primary;
    border-width: 1px 1px 1px 4px;
}

.alert-heading {
  border-radius: 0%;
  font-size: 14px;
  text-transform: uppercase;
  @include themify {
    color: themed('color-gray-100-HQ');
  }
  //color: $informix-black-primary;
}

.alert-link {
  border-radius: 0%;
  font-size: 12px;
  color: $informix-blue-primary;
}

.alert-info {
  @include themify {
    color: themed('color-info-text-HQ');
    background-color: themed('color-info-bg');
    border-color: themed('color-info-border-HQ');
  }
  //color: $informix-black-primary;
 // border-color: $informix-blue-50;
}

.alert-warning {
  @include themify {
    color: themed('color-warning-text-HQ');
    background-color: themed('color-warning-bg');
    border-color: themed('color-warning-border');
  }
  //color: $informix-black-primary;
  //border-color: $informix-gold-primary;
}

.alert-danger {
  @include themify {
    color: themed('color-danger-text-HQ');
    background-color: themed('color-danger-bg');
    border-color: themed('color-danger-border-HQ');
  }
  //color: $informix-black-primary;
  //border-color: $informix-red-primary;
}

.alert-success {
  @include themify {
    color: themed('color-success-text');
    background-color: themed('color-success-bg');
    border-color: themed('color-success-border-HQ');
  }
  //color: $informix-black-primary;
  //border-color: $informix-green-30;
}

.breadcrumb {
  @include themify($themes) {
    border-color: themed('color-gray-10-HQ1');
    background: themed('color-white');
    color: themed('color-label');
  }
    //background: $informix-white-primary;
    border-radius: 0%;
    font-size: 12px;
    //border-color: $informix-light-gray-40;
    border-style: solid;
    border-width: 0 0 1px 0;
    margin: 0;
}

.inline-edit {
  padding: 5px;
  border-color: $informix-light-gray-10;
  border-style: solid;
  border-width: 0 0 0 3px;
  margin-left: 10px;
}

.container-fluid.page-container {
  @include themify($themes) {
    background-color: themed('color-white');
    border-color: themed('color-gray-10-HQ');
  }
    //background-color: $informix-white-primary;
    padding: 20px;
    margin: 0 10px 10px 10px;
    border-style: solid;
    //border-color: $informix-light-gray-20;
    border-width: 10px 0 0 0;
    width: initial;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">";
}

.breadcrumb-item {
    max-width:25%;
}
.nav-tabs {
  @include themify($themes) {
    border-bottom: 2px solid themed('color-gray-20');
  }
}

.nav-tabs .nav-link.active {
  @include themify($themes) {
    background-color: themed('color-white');
    border-color: themed('color-blue-HQ');
    color: themed('color-blue-HQ');
  }
    //color: $informix-blue-primary;
    //background-color: $informix-white-primary;
    //border-color: $informix-blue-primary;
    border-style: solid;
    border-width: 0 0 4px 0;
    font-size: 14px;
    font-family: 'Roboto-Bold';
}

 .nav-tabs .nav-link {
  @include themify($themes) {
    color: themed('color-nav-link-HQ');
  }
    //color: $informix-light-gray-70;
    font-size: 14px;
    border: 0px solid transparent;

    &.disabled {
      opacity: 0.5;
    }
}

.dropdown-item {
    font-family: 'Roboto-Light';
    font-size: 14px;
    color: $informix-black-primary;
    background-color: $informix-white-primary;
    width: 180px;
    height: 32px;

}

.dropdown-item:hover {
  @include themify($themes) {
    background-color: themed('color-light-gray-20');
    color: themed('color-label');
  }
     //color: $informix-black-primary;
     //background-color: $informix-light-gray-20;
}

.dropdown-item:active {
    font-family: 'Roboto-Regular';
    font-size: 14px;
    color: $informix-black-primary;
    background-color: $informix-light-gray-20;
}

.text-muted {
  color: $informix-gray-20 !important;
}

.modal-content {
  @include themify($themes) {
    background-color: themed('color-white');
    color: themed('color-label');
  }
  border-radius: 0%;
}

.modal-header {
  @include themify($themes) {
    border-bottom: 1px solid themed('color-gray-20');
  }
}

.modal-footer {
  @include themify($themes) {
    border-top: 1px solid themed('color-gray-20');
  }
}

.pagination-sm .page-link {
  @include themify($themes) {
    background-color: themed('color-white');
    border-color: themed('color-gray-20');
  }
}

.page-item.active .page-link {
  @include themify($themes) {
    background-color: themed('color-blue');
    border-color: themed('color-blue');
  }
}

.table thead th {
  @include themify($themes) {
    background-color: themed('color-gray-10-HQ');
    border-bottom: 1px solid themed('color-gray-20-HQ');
  }
    //background-color: $informix-light-gray-20;
    //border-bottom: 1px solid $informix-light-gray-20;
    height: 40px;
    vertical-align: middle;
}

.table th,.table td {
  @include themify($themes) {
    border-top: 1px solid themed('color-gray-20');
  }
    //border-top: 1px solid $informix-light-gray-20;
    vertical-align: middle;
}

.table {
  @include themify($themes) {
    color: themed('color-table-text-HQ');
    border: 2px solid themed("color-gray-20");
  }
   // border: 2px solid $informix-light-gray-20;
}

.btn-icon, .btn-icon:hover {
    border: none;
    font-size: 15px;
    //color: $informix-light-gray-70;
    @include themify($themes) {
      color: themed('color-nav-link-HQ1');
    }
}

.btn-icon:disabled {
    border: none;
    // background: $informix-white-primary;
    font-size: 15px;
    @include themify($themes) {
      color: themed('color-nav-link-HQ1');
    }
    //color: $informix-light-gray-70;
    opacity: 0.5;
}

.search-input::-ms-clear {
  display: none !important;
}

.chartjs-popover {
  max-width: none;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  pointer-events: none;

  @each $direction in top, right, bottom, left {
    &.bs-popover-#{$direction} {
      .arrow::before {
        border: none;
      }

      .arrow::after {
        border-#{$direction}-color: rgba(0, 0, 0, 0.8);
      }
    }
  }

  .popover-body {
    color: #FFF;
  }

  .series-color {
    vertical-align: middle;

    div {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 5px;
    }
  }
}

.btn-container {
  border-radius: 15px;
  @include themify($themes) {
    background-color: themed('color-btn-toggle-bg-HQ');
  }
}

.btn-container button {
  color: $onedb-blue-primary;
  border-radius: 15px;
}

.btn-container {
  .btn.btn-style {
    background-color: $onedb-blue-primary;
    color: $onedb-white-primary !important;
    outline: none;
  }

  .btn:hover , :not(.btn.btn-style, .btn.btn-style:focus, .btn.btn-style:hover) {
    @include themify($themes) {
      color: themed('color-black');
    }
  }
}

.popover-no-padding {
  .popover-body {
    padding: 0;
  }
}
.popover-arrow-hidden {
  .popover-arrow.arrow {
    display: none;
  }
}
.er-graph-popover{
  .popover-body.node-info{
    width: 230px;
  }
}
.flex-1 {
  flex: 1;
}

.close.input-clear {
  float: none;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  z-index: 5;
}

.server-label {
  max-width: 12em;
  line-height: 1.6;
}

// Fix for ngx-bootstrap's broken dropup style
// TODO: Remove when no longer needed
.dropup .dropdown-menu {
  bottom: auto;
}

.mw-none {
  max-width: none;
}

.bs-datepicker-head {
  background-color: $informix-blue-primary !important;
}

.bs-datepicker-body table {
  td.week span {
    color: $informix-blue-primary !important;
  }

  td span.selected {
    background-color: $informix-blue-primary !important;
  }
}

.disabled-text {
  opacity: 0.5;
 }
 .tab-index-popover.popover {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, .4);
  border-radius: 0px;
  border: none;
}

.tab-index-popover .popover-body {
  @include themify($themes) {
    background-color: themed('color-white');
    color: themed('color-black');
  }
  padding-top: 6px;
  padding-left: 6px;
  padding-bottom: 2px;
}

.input-style {
  border: 1px solid $informix-light-gray-primary;
}

.input-style:disabled {
  @include themify($themes) {
    background-color: themed('color-gray-10-HQ');
    color: themed('color-input-text-disabled-HQ');
  }
 // background-color: $informix-light-gray-40;
  border: none;
}

.select-sm {
  width: 155px;
}

.outline-none button:focus {
  outline: 0;
  box-shadow: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 41px;
  height: 8.4px;
  top: 4px;
}

.switch input,
.toggle-button input {
  opacity: 0;
}

.slider {
  position: absolute !important;
  cursor: pointer;
  top: 0;
  left: 4px;
  right: -4px;
  bottom: 0;
  background-color: $informix-light-gray-40;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 0px;
  bottom: -3.9px;
  background-color: $informix-light-gray-60;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: $informix-blue-30;
}

input:checked+.slider:before {
  background-color: $informix-blue-primary;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.toggle-slider.round,
.slider.round {
  border-radius: 34px;
}

.toggle-slider.round:before,
.slider.round:before {
  border-radius: 50%;
}

.toggle-button {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
  top: 4px;
}

.toggle-slider {
  position: absolute !important;
  cursor: pointer;
  top: -4px;
  left: 4px;
  bottom: 5px;
  right: -5px;
  background-color: $informix-white-primary;
  border: solid 1px $informix-light-gray-60;
  -webkit-transition: .4s;
  transition: .4s;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 3px;
  top: 2px;
  background-color: $informix-light-gray-60;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.toggle-slider {
  background-color: $informix-blue-primary;
}

input:checked+.toggle-slider:before {
  height: 10px;
  width: 10px;
  left: 20px;
  bottom: 3px;
  background-color: $informix-white-primary;
}

.time-input-width {
  width: 47px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

::ng-deep .bs-datepicker-head bs-datepicker-navigation-view {
  display: flex !important;
  justify-content: space-between !important;
}

.privilege-text {
  margin-right: 1%;
  width: 30px;
  line-height: 2;
  padding: 0.25em 0.4em;
  color: $informix-white-primary;
}

.icon-padding {
  padding-top: 3px;
  padding-bottom: 3px;
}
bs-dropdown-container {
  z-index: 1060;
}
.transparent-overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  right: 0;
  z-index: 1000;
  top:0;
  background-color: rgba(12, 12, 12, 0.253);
}
ul.li-list li{
  border-left: 1px solid $informix-light-white-30;
  .outline-override:focus{
    outline: none;
  }
}
.navbar-dark .navbar-nav .nav-link {
  @include themify($themes) {
    color: themed('color-informix-header-text') !important;
  }
 // color: $informix-white-primary;
}
.navbar-dark-onedb .navbar-nav .nav-link {
  color: $informix-blue-100;
}
div.hdr-type{
  span{
    display: inline-block;
    vertical-align: middle;
  }
  span.badge{
    color: $informix-white-primary;
    background-color: $informix-custom-gray;
    border-radius: 10px;
    font-size: 10px;
  }
}

ng-multiselect-dropdown {
  .multiselect-dropdown {
    .dropdown-btn {
      border-radius: 0% !important;
      @include themify($themes) {
        border-color: themed('color-gray-20') !important;
      }
      .dropdown-multiselect__caret{
        line-height: 10px !important;
      }
    }
    .dropdown-list .filter-textbox input {
      padding: 0px !important;
    }
    .disabled{
      pointer-events: none;
    }
  }
}

.prerequisties-modal {
  max-width: 63rem;
  .modal-content{
    height: auto;
  }
  .close{
    right: 0;
    z-index: 1;
  }
  .modal-body .wrapper{
    height: auto;
  }
  
}

strong.bold{
  font-family: 'Roboto-Bold';
}
.table-active, .table-active > th, .table-active > td {
  @include themify($themes) {
    background-color: themed('color-table-hover-HQ') !important;
  }
}

.list-group-item {
  @include themify($themes) {
    background-color: themed('color-white');
    color: themed('color-input-text-HQ1')
  }
  &:hover {
    @include themify($themes) {
      background-color: themed('color-gray-10-HQ2');
      color: themed('color-gray-70-HQ');
    }
  }
}

// .btn-container {
//   .btn.btn-style {
//     background-color: $informix-blue-primary;
//     color: $informix-white-primary !important;
//     outline: none;
//   }

//   .btn:hover , :not(.btn.btn-style, .btn.btn-style:focus, .btn.btn-style:hover) {
//     @include themify($themes) {
//       color: themed('color-black-HQ');
//     }
//   }
// }

// .dropdown-item:focus, li a.dropdown-item:hover, li a.dropdown-item:focus {
//   @include themify($themes) {
//     background-color: themed('color-gray-10');
//     color: themed('color-label');
//   }
// }

.form-control[readonly], .form-control-sm:disabled, .form-control-sm[readonly] {
  @include themify($themes) {
    background-color: themed('color-gray-10');
    color: themed('color-input-text-disabled-HQ');
  }
}

.form-control:disabled {
  @include themify($themes) {
    background-color: themed('color-gray-20');
    color: themed('color-input-text-disabled');
  }
}

.dropdown-menu {
  border-radius: 0%;
  @include themify($themes) {
    border: 1px solid themed('color-gray-20-HQ');
    background-color: themed('color-white');
  }
}

.dropdown-item, li a.dropdown-item {
  //cursor: pointer;
  font-family: 'Roboto-Light';
  font-size: 14px;
  @include themify($themes) {
    background-color: themed('color-white');
    color: themed('color-label');
  }
  width: 180px;
  height: 40px;
  padding: 0.7rem;
  padding-left: 1.1rem;
}

hr {
  @include themify($themes) {
    border-color: themed('color-gray-20-HQ');
  }
}

// a {
//   @include themify($themes) {
//     color: themed('color-blue-HQ');
//   }
// }

// a:hover{
//   @include themify($themes) {
//     color: themed('color-anchor-hover');
//   }
//   text-decoration: none;
// }

// .card-body {
//   @include themify {
//     color: themed('color-gray-100-HQ1');
//   }
// }

input {
  filter: none;
}

input:-webkit-autofill, input:-webkit-autofill:focus, input:-internal-autofill-selected,
input:-webkit-autofill:active {
  @include themify($themes) {
    background-color: themed('color-white');
    border-color: themed('color-input-border');
    -webkit-text-fill-color: themed('color-input-text') !important;
    -webkit-box-shadow: 0 0 0 30px themed('color-white') inset;
  }
}

.form-control, .form-control-sm, .memory-input {
  @include themify($themes) {
    background: themed('color-white');
    color: themed('color-input-text-HQ1');
  }
  border-radius: 0%;
  font-size: 12px;
}

.theme-dark{
  color: $informix-gray-15;
  background-color: $informix-gray-100;
}

.theme-dark ::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: $informix-gray-100;
}

.theme-dark ::-webkit-scrollbar
{
	width: 12px;
	background-color: $informix-gray-100;
}

.theme-dark ::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: $informix-gray-50;
}

// label {
//   @include themify($themes) {
//     color: themed('color-gray-100-HQ');
//   }
// }

h1, h2, h3, h4, h5, h6 {
  @include themify($themes) {
    color: themed('color-gray-100');
  }
}

.popover{
  @include themify($themes) {
    border: 1px solid themed('color-gray-20');
    box-shadow: 0 0 10px 0 themed('color-datepicker-shadow');
    background-color: themed('color-white') !important;
  }
}

.popover-color {
  border-radius: 0%;
  @include themify($themes) {
    border: 1px solid themed('color-gray-20');
    box-shadow: 0 0 10px 0 themed('color-datepicker-shadow');
    background-color: themed('color-white') !important;
  }
  @each $direction in top, right, bottom, left {
    &.bs-popover-#{$direction} {
      .arrow::before {
        border: none;
      }

      .arrow::after {
        @include themify($themes) {
          border-#{$direction}-color: themed('color-white');
        }
      }
    }
  }

  .popover-body {
    @include themify($themes) {
      background-color: themed('color-white');
      color: themed('color-black');
    }
  }
}

.popover-header {
  @include themify($themes) {
    background-color: themed('color-gray-10');
    border-bottom: 1px solid themed('color-gray-10');
    border-radius: 0%;
  }
}

.input-group-text {
  @include themify($themes) {
    background-color: themed('color-gray-20');
    color: themed('color-light-gray-70');
    border: 1px solid themed('color-input-border-HQ');
  }
}

.close {
  text-shadow: none;
  @include themify($themes) {
    color: themed('color_cross_icon');
  }
}

.bs-datepicker {
  @include themify($themes) {
    background-color: themed('color-white');
    box-shadow: 0 0 10px 0 themed('color-datepicker-shadow');
  }
}

.bs-datepicker-body {
  @include themify($themes) {
    border: 1px solid themed('color-gray-20');
  }
}

.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  @include themify($themes) {
    color: themed('color-gray-20');
  }
}

.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  @include themify($themes) {
    color: themed('color-date');
  }
  opacity: 0.5;
}

.bs-datepicker-body table td {
  @include themify($themes) {
    color: themed('color-date');
  }
}

.text-danger {
  @include themify {
    color: themed('color-danger-icon') !important;
  }
}

.text-info {
  @include themify {
    color: themed('color-info-icon') !important;
  }
}

.text-warning {
  @include themify {
    color: themed('color-warning-icon') !important;
  }
}

.text-success {
  @include themify {
    color: themed('color-success-icon') !important;
  }
}
.password-hide-show, .show-inner-icon {
  &.inner-addon {
    position: relative;
  }

  &.right-addon input {
    padding-right: 32px !important;
  }

  &.inner-addon>.icon {
    position: absolute;
    padding: 10px;
    cursor: pointer;
    z-index: 4;
    color: $informix-black-primary;
    @include themify {
      color: themed('color-black') !important;
    }
  }

  &.right-addon>.icon {
    right: 0;
  }
}

.master-server {
  background-color: $informix-blue-primary;
  color: $informix-white-primary;
  padding: 2px 8px;
  margin-left: 4px;
}

// action loader
.showLoader {
  pointer-events: none;
  opacity: 0.4;
}

.loading-wrapper {
  top: 0;
  left: 0;
  z-index: 1;
  .backdrop {
      top: 0;
      cursor: wait;
  }
  .alert-info {
      top: 40%;
      transform: translate(0, -50%);
      z-index: 3;
  }
}
.item-stats, .table-border {
    @include themify($themes) {
      border: 1px solid themed("color-gray-20");
    }
    // overflow-y: hidden;
  }

.table-hover tr:hover {
  @include themify($themes) {
    background-color: themed('color-table-hover');
  }
}

.agents-list{
  @include themify($themes) {
    background-color: themed("color-white");
  }
}

.db-border {
  @include themify($themes) {
    border: 1px solid themed("color-gray-20");
  }
 }

 .pieChartValue{
  @include themify($themes) {
    border-color: themed("color-black");
  }
 }

 .er-sidebar ul.server-lists {
   li {
    &:hover{
      @include themify($themes) {
        background-color: themed("color-gray-20");
      }
    }
    &.selected{
      @include themify($themes) {
        background-color: themed("color-gray-20");
      }
    }
   }
 }

 .ng-select {
    .ng-select-container {
      border-radius: 0;
      min-height: 28px;
      min-width: 200px;
      @include themify($themes) {
        background-color: themed("color-white");
      }
    }
    .ng-clear-wrapper{
      display: none;
    }
 }

/* Dark theme properties for <ng-select> */
 .theme-dark .ng-select {

  .ng-select-container, .ng-dropdown-panel {
    border-color: map-get(map-get($themes, "dark"), "color-gray-20") !important;
  }
  .ng-dropdown-panel-items .ng-option {
      background-color: map-get(map-get($themes, "dark"), "color-white") !important;

    &.ng-option-selected {
      background-color: map-get(map-get($themes, "dark"), "color-gray-20") !important;
    }
    .ng-option-label {
      color: map-get(map-get($themes, "dark"), "color-black") !important;
    }
  }
  .ng-value-container {
    .ng-value {
      background-color: map-get(map-get($themes, "dark"), "color-light-gray-20") !important;

    }
    .ng-value-icon {
      border-color: map-get(map-get($themes, "dark"), "color-gray-20") !important;
    }
  }
 }
 .theme-dark {
    .sensor-chart-graph-overlay, .permissions-disabled-overlay {
      background-color: #47474770 !important;
    }
    .dashboard-panel-header {
      button {
        color: map-get(map-get($themes, "dark"), "color-light-gray-30");
      }
    }
    .steps .guide-list-item{
      background: map-get(map-get($themes, "dark"), "color-light-gray") !important;
      h5{
        color: map-get(map-get($themes, "dark"), "color-light-gray-40") !important;
      }
    }
    div.hdr-banner {
      background-color: map-get(map-get($themes, "dark"), "color-gray-20") !important;
      border-color: map-get(map-get($themes, "dark"), "color-gray-20") !important;
      .server-type {
        color: map-get(map-get($themes, "dark"), "color-black") !important;
      }
    }
    .toggle-slider{
      border-color: map-get(map-get($themes, "dark"), "color-gray-20") !important;
      background-color: map-get(map-get($themes, "dark"), "color-gray-20");
    }
}

   .li-background{
      @include themify($themes) {
        color: themed("color-black");
      }
      &:hover {
        @include themify($themes) {
          background: themed("color-gray-20");
        }
      }
    }

