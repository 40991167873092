/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019. All Rights Reserved.
 *******************************************************************************/

.icon-2x {
  font-size: 2em;
}

.icon-3x {
  font-size: 3em;
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
  display: inline-block;
  color: $informix-gray-20;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@mixin icon-rotate ($degrees, $rotation) {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=#{$rotation})";
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  transform: rotate($degrees);
}

.icon-rotate-90 {
  @include icon-rotate(90deg, 1);
  display: inline-block;
}

.icon-rotate-180 {
  @include icon-rotate(180deg, 2);
  display: inline-block;
}

.icon-rotate-270 {
  @include icon-rotate(270deg, 3);
  display: inline-block;
}
