/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2023. All Rights Reserved.
 *******************************************************************************/

// Informix colors
$informix-background-color: #222;

//new variable start from onedb explore
$informix-blue-110: #0786CF;
$informix-blue-70-HQ: #0066B0;
$informix-gray-100: #262626;
$informix-gray-100-HQ: rgba(0, 0, 0, 0.125);
$informix-blue-100: #092B51;
$informix-gray-15: #E6E6E6;
$informix-gray-80: #474747;
$informix-gray-50-HQ: #8F8F8F;
$informix-gray-90: #363636;
$informix-gray-110: #121212;
$informix-cool-gray-10: #F2F4F5;
$informix-cool-gray-15: #DAE1E6;
$informix-cool-gray-20: #D3D9DE;
$informix-cool-gray-30: #B3BEC7;
$informix-cool-gray-40: #93A2AD;
$informix-cool-gray-50: #7E909E;
$informix-cool-gray-60: #587387;
$informix-cool-gray-70: #3D5466;
$informix-cool-gray-80: #354958;
$informix-cool-gray-90: #273641;
$informix-cool-gray-100: #1C262E;
$informix-cool-gray-110: #12191F;
$informix-gray-100: #262626;
$informix-gray-40: #ADADAD;
$informix-gray-60-HQ: #ADADAD;

$informix-red-30-HQ: #FFADAD;
$informix-red-60: #F52929;
$informix-red-70: #EB0909;
$informix-red-80: #C10C0D;
$informix-red-90: #A00C0C;
$informix-red-100: #730C0C;

$informix-orange-100: #691E08;

$informix-yellow-50: #EEA600;
$informix-yellow-60: #CE8200;
$informix-yellow-70: #AE6402;
$informix-yellow-80: #8E4A06;
$informix-yellow-90: #6E3606;
$informix-yellow-100: #4C2609;

$informix-green-50: #05BE62;
$informix-green-60: #029C54;
$informix-green-70: #007744;
$informix-green-80: #04633D;
$informix-green-90: #054D33;
$informix-green-100: #07432F;

$informix-lime-100: #2E4005;

$informix-teal-100: #033B46;

$informix-purple-primary: #6A2E99;

$informix-purple-10: #F9EEFF;
$informix-purple-20: #F0D3FF;
$informix-purple-30: #E4B6FF;
$informix-purple-40: #D694F9;
$informix-purple-50: #B476DD;
$informix-purple-60: #9153BB;
$informix-purple-70: #7E40AC;
$informix-purple-80: #6A2E99;
$informix-purple-90: #561A87;
$informix-purple-100: #460A7A;

$informix-indigo-10: #EDF5FF;
$informix-indigo-20: #C8DFFF;
$informix-indigo-30: #A9C7FC;
$informix-indigo-40: #789DF4;
$informix-indigo-50: #5C6FCB;
$informix-indigo-60: #4C5AB7;
$informix-indigo-70: #4D4DB9;
$informix-indigo-80: #44339F;
$informix-indigo-90: #362284;
$informix-indigo-100: #2A1A6A;

$informix-pink-10: #FFECFC;
$informix-pink-20: #FFCBF6;
$informix-pink-30: #FFADEF;
$informix-pink-40: #FF7DE5;
$informix-pink-50: #E753BF;
$informix-pink-60: #C8329F;
$informix-pink-70: #AA157D;
$informix-pink-80: #8F0465;
$informix-pink-90: #71054D;
$informix-pink-100: #510736;

//new variable end from onedb explore

$informix-blue-primary: #0066b3;
$informix-blue-10: #b9e6fa;
$informix-blue-20: #8cd7fa;
$informix-blue-30: #69b4e1;
$informix-blue-40: #4696d2;
$informix-blue-50: #0578c3;
$informix-blue-60: #00518f;
$informix-blue-70: #003d6b;
$informix-blue-80: #002847;
$informix-blue-90: #001423;
$informix-blue-100: #0066b0;


$informix-white-primary: #ffffff;
$informix-light-white-30: #ffffff30;
$informix-black-primary: #000000;

$informix-gray-primary: #4b4b4b;
$informix-gray-10: #828282;
$informix-gray-10-HQ2: #F2F2F2;
$informix-gray-20: #717171;
$informix-gray-20-HQ: #DEDEDE;
$informix-gray-30: #6d6e70;
$informix-gray-30-HQ: #C6C6C6;  
$informix-gray-40: #555555;
$informix-gray-50: #383838;
$informix-gray-60: #252525;
$informix-gray-70: #1c1c1c;
$informix-gray-70-HQ: #5C5C5C;


$informix-light-gray-primary: #c6c6c6;
$informix-light-gray-10: #f5f5f5;
$informix-light-gray-20: #efefef;
$informix-light-gray-30: #e6e6e6;
$informix-light-gray-40: #e2e2e2;
$informix-light-gray-50: #d2d2d2;
$informix-light-gray-60: #aaaaaa;
$informix-light-gray-70: #8d8d8d;
$informix-light-gray-80: #dedede;

$informix-red-primary: #e0182d;
$informix-red-10:#fcd8d8 ;
$informix-red-20: #f78a8a;
$informix-red-30: #d15757;
$informix-red-40: #bf1010;
$informix-red-50: #8f0c0c;

$informix-ruby-primary: #eb1946;
$informix-ruby-10: #f8d8e0;
$informix-ruby-20: #f8b2c1;
$informix-ruby-30: #f58ca2;
$informix-ruby-40: #f16583;
$informix-ruby-50: #ee3f64;
$informix-ruby-60: #c3143a;
$informix-ruby-70: #9c102e;
$informix-ruby-80: #750c23;
$informix-ruby-90: #4e0817;

$informix-orange-primary: #f5821e;
$informix-orange-10: #fdead9;
$informix-orange-20: #fbd5b4;
$informix-orange-30: #fac08e;
$informix-orange-40: #f8ab69;
$informix-orange-50: #f69643;
$informix-orange-60: #cc6c19;
$informix-orange-70: #a35614;
$informix-orange-80: #7a410f;
$informix-orange-90: #512b0a;

$informix-yellow-primary: #f9db00;
$informix-yellow-10:#fef9d4 ;
$informix-yellow-20: #fced80;
$informix-yellow-30: #c7af00;
$informix-yellow-40: #958300;

$informix-gold-primary: #fab914;
$informix-gold-10: #fdead9;
$informix-gold-20: #fde7b0;
$informix-gold-30: #fcdc89;
$informix-gold-40: #fbd062;
$informix-gold-50: #fac43b;
$informix-gold-60: #d09a10;
$informix-gold-70: #a67b0d;
$informix-gold-80: #7d5c0a;
$informix-gold-90: #533d06;

$informix-green-primary: #79d732;
$informix-green-10: #f2fbec ;
$informix-green-20: #bceb99;
$informix-green-30: #589d25;
$informix-green-40: #467324;

$informix-lime-primary: #bed732;
$informix-lime-10: #f4f8dc;
$informix-lime-20: #e9f1ba;
$informix-lime-30: #deeb98;
$informix-lime-40: #d3e476;
$informix-lime-50: #c8dd54;
$informix-lime-60: #98ac28;
$informix-lime-70: #72811e;
$informix-lime-80: #4c5614;
$informix-lime-90: #262b0a;

$informix-teal-primary: #00afbe;
$informix-teal-10: #d4f1f4;
$informix-teal-20: #aae4e9;
$informix-teal-30: #7fd7de;
$informix-teal-40: #55c9d3;
$informix-teal-50: #2abcc8;
$informix-teal-60: #008c98;
$informix-teal-70: #006972;
$informix-teal-80: #00464c;
$informix-teal-90: #002326;

$informix-aqua-primary: #5ec1ef;
$informix-aqua-10: #e4f5fc;
$informix-aqua-20: #c9eafa;
$informix-aqua-30: #afe0f7;
$informix-aqua-40: #94d6f4;
$informix-aqua-50: #79cbf2;
$informix-aqua-60: #4b9abf;
$informix-aqua-70: #38748f;
$informix-aqua-80: #264d60;
$informix-aqua-90: #132730;

$informix-light-blue-primary: #54b2ed;
$informix-light-blue-10: #e2f2fc;
$informix-light-blue-20: #aad9f6;
$informix-light-blue-30: #438ebe;
$informix-light-blue-40: #326b8e;

$informix-violet-primary: #5a2d91;
$informix-violet-10: #e3dcec;
$informix-violet-20: #c8b9da;
$informix-violet-30: #ac96c8;
$informix-violet-40: #9173b5;
$informix-violet-50: #7550a3;
$informix-violet-60: #482474;
$informix-violet-70: #361b57;
$informix-violet-80: #24123a;
$informix-violet-90: #12091d;

// Color map
$informix-colors: (
  blue: (
    primary: $informix-blue-primary,
    10: $informix-blue-10,
    20: $informix-blue-20,
    30: $informix-blue-30,
    40: $informix-blue-40,
    50: $informix-blue-50,
    60: $informix-blue-60,
    70: $informix-blue-70,
    80: $informix-blue-80,
    90: $informix-blue-90
  ),
  black: (
    primary: $informix-black-primary,
  ),
  white: (
    primary: $informix-white-primary,
  ),
  gray: (
    primary: $informix-gray-primary,
    10: $informix-gray-10,
    20: $informix-gray-20,
    30: $informix-gray-30,
    40: $informix-gray-40,
    50: $informix-gray-50,
    60: $informix-gray-60,
    70: $informix-gray-70
  ),
  light-gray: (
    primary: $informix-light-gray-primary,
    10: $informix-light-gray-10,
    20: $informix-light-gray-20,
    30: $informix-light-gray-30,
    40: $informix-light-gray-40,
    50: $informix-light-gray-50,
    60: $informix-light-gray-60,
    70: $informix-light-gray-70
  ),
  red: (
    primary: $informix-red-primary,
    10: $informix-red-10,
    20: $informix-red-20,
    30: $informix-red-30,
    40: $informix-red-40,
    50: $informix-red-50
  ),
  ruby: (
    primary: $informix-ruby-primary,
    10: $informix-ruby-10,
    20: $informix-ruby-20,
    30: $informix-ruby-30,
    40: $informix-ruby-40,
    50: $informix-ruby-50,
    60: $informix-ruby-60,
    70: $informix-ruby-70,
    80: $informix-ruby-80,
    90: $informix-ruby-90
  ),
  orange: (
    primary: $informix-orange-primary,
    10: $informix-orange-10,
    20: $informix-orange-20,
    30: $informix-orange-30,
    40: $informix-orange-40,
    50: $informix-orange-50,
    60: $informix-orange-60,
    70: $informix-orange-70,
    80: $informix-orange-80,
    90: $informix-orange-90
  ),
  yellow: (
    primary: $informix-yellow-primary,
    10: $informix-yellow-10,
    20: $informix-yellow-20,
    30: $informix-yellow-30,
    40: $informix-yellow-40
  ),
  gold: (
    primary: $informix-gold-primary,
    10: $informix-gold-10,
    20: $informix-gold-20,
    30: $informix-gold-30,
    40: $informix-gold-40,
    50: $informix-gold-50,
    60: $informix-gold-60,
    70: $informix-gold-70,
    80: $informix-gold-80,
    90: $informix-gold-90
  ),
  green: (
    primary: $informix-green-primary,
    10: $informix-green-10,
    20: $informix-green-20,
    30: $informix-green-30,
    40: $informix-green-40
  ),
  lime: (
    primary: $informix-lime-primary,
    10: $informix-lime-10,
    20: $informix-lime-20,
    30: $informix-lime-30,
    40: $informix-lime-40,
    50: $informix-lime-50,
    60: $informix-lime-60,
    70: $informix-lime-70,
    80: $informix-lime-80,
    90: $informix-lime-90
  ),
  teal: (
    primary: $informix-teal-primary,
    10: $informix-teal-10,
    20: $informix-teal-20,
    30: $informix-teal-30,
    40: $informix-teal-40,
    50: $informix-teal-50,
    60: $informix-teal-60,
    70: $informix-teal-70,
    80: $informix-teal-80,
    90: $informix-teal-90
  ),
  aqua: (
    primary: $informix-aqua-primary,
    10: $informix-aqua-10,
    20: $informix-aqua-20,
    30: $informix-aqua-30,
    40: $informix-aqua-40,
    50: $informix-aqua-50,
    60: $informix-aqua-60,
    70: $informix-aqua-70,
    80: $informix-aqua-80,
    90: $informix-aqua-90
  ),
  light-blue: (
    primary: $informix-light-blue-primary,
    10: $informix-light-blue-10,
    20: $informix-light-blue-20,
    30: $informix-light-blue-30,
    40: $informix-light-blue-40
  ),
  violet: (
    primary: $informix-violet-primary,
    10: $informix-violet-10,
    20: $informix-violet-20,
    30: $informix-violet-30,
    40: $informix-violet-40,
    50: $informix-violet-50,
    60: $informix-violet-60,
    70: $informix-violet-70,
    80: $informix-violet-80,
    90: $informix-violet-90
  ),
);

// Override bootstrap's colors with Informix colors
$red: $informix-red-primary !default;
$orange: $informix-orange-primary !default;
$yellow:$informix-gold-primary !default;
$green: $informix-green-primary !default;
$blue: $informix-blue-primary !default;
$teal: $informix-teal-primary !default;
$pink: $informix-ruby-40 !default;
$purple: $informix-violet-primary !default;

$white: $informix-white-primary !default;
$gray-100: $informix-light-gray-10 !default;
$gray-200: $informix-light-gray-20 !default;
$gray-300: $informix-light-gray-40 !default;
$gray-400: $informix-light-gray-primary !default;
$gray-500: $informix-light-gray-60 !default;
$gray-600: $informix-gray-30 !default;
$gray-700: $informix-gray-40 !default;
$gray-800: $informix-gray-50 !default;
$gray-900: $informix-gray-70 !default;
$black: $informix-black-primary !default;

// Font
$font-family-sans-serif: Roboto-Regular, "Helvetica Neue", Arial, sans-serif !default;
$font-size-base: 0.75rem !default;
$font-size-lg: 1rem !default;
$font-size-sm: .7rem !default;
$font-size-xs: .6rem !default;

$h1-font-size: 2rem !default;
$h2-font-size: 1.75rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: 0.75rem !default;

// Tables
$table-cell-padding: .5rem !default;
$table-sm-cell-padding: .2rem !default;

// Forms
$form-check-input-margin-y: .2rem !default;

// Body
$body-bg: $informix-light-gray-20 !default;

// Brand colors
$primary: $informix-blue-primary !default;
$secondary: $white !default;
$success: $informix-green-30 !default;
$info: $informix-blue-primary !default;
$warning: $informix-gold-primary !default;
$danger: $informix-red-primary !default;

// Progress bars
$progress-height: 0.6rem;

// hdr server type badge
$informix-custom-gray: #6c6c6c;

// Enterprise Replication 
$er-container-height: 80vh;
$sidebar-width: 500px;
$root-node-color: $informix-blue-primary;
$nonroot-node-color: $informix-violet-40;
$leaf-node-color: $informix-green-30;









// OneDB colors
$onedb-background-color: #222;

$onedb-blue-primary: #0066B0;
$onedb-blue-10: #D9F9FF;
$onedb-blue-20: #AFEBF9;
$onedb-blue-30: #71D9F3;
$onedb-blue-40: #2ABDEA;
$onedb-blue-50: #14A1DE;
$onedb-blue-60: #0786CF;
$onedb-blue-70: #0066B0;
$onedb-blue-80: #01539B;
$onedb-blue-90: #06396D;
$onedb-blue-100: #092B51;

$onedb-white-primary: #ffffff;
$onedb-light-white-30: #ffffff30;
$onedb-black-primary: #000000;

$onedb-light-gray-primary: #C6C6C6;
$onedb-gray-primary: #474747;
$onedb-gray-10: #F2F2F2;
$onedb-gray-15: #E6E6E6;
$onedb-gray-20: #DEDEDE;
$onedb-gray-30: #C6C6C6;
$onedb-gray-40: #ADADAD;
$onedb-gray-50: #8F8F8F;
$onedb-gray-60: #737373;
$onedb-gray-70: #5C5C5C;
$onedb-gray-80: #474747;
$onedb-gray-90: #363636;
$onedb-gray-100: #262626;
$onedb-gray-110: #121212;

$onedb-cool-gray-10: #F2F4F5;
$onedb-cool-gray-15: #DAE1E6;
$onedb-cool-gray-20: #D3D9DE;
$onedb-cool-gray-30: #B3BEC7;
$onedb-cool-gray-40: #93A2AD;
$onedb-cool-gray-50: #7E909E;
$onedb-cool-gray-60: #587387;
$onedb-cool-gray-70: #3D5466;
$onedb-cool-gray-80: #354958;
$onedb-cool-gray-90: #273641;
$onedb-cool-gray-100: #1C262E;
$onedb-cool-gray-110: #12191F;

$onedb-red-primary: #C10C0D;
$onedb-red-10: #FFF2F2;
$onedb-red-20: #FFCECE;
$onedb-red-30: #FFADAD;
$onedb-red-40: #FF7A7A;
$onedb-red-50: #F95454;
$onedb-red-60: #F52929;
$onedb-red-70: #EB0909;
$onedb-red-80: #C10C0D;
$onedb-red-90: #A00C0C;
$onedb-red-100: #730C0C;

$onedb-orange-primary: #FF8A00;
$onedb-orange-10: #FFF6E2;
$onedb-orange-20: #FFE1A6;
$onedb-orange-30: #FFC766;
$onedb-orange-40: #FFAF38;
$onedb-orange-50: #FF8A00;
$onedb-orange-60: #E26900;
$onedb-orange-70: #C95000;
$onedb-orange-80: #AE3D04;
$onedb-orange-90: #882E07;
$onedb-orange-100: #691E08;

$onedb-yellow-primary: #EEA600;
$onedb-yellow-10: #FFFCD5;
$onedb-yellow-20: #FFEF86;
$onedb-yellow-30: #FFDF41;
$onedb-yellow-40: #FFC919;
$onedb-yellow-50: #EEA600;
$onedb-yellow-60: #CE8200;
$onedb-yellow-70: #AE6402;
$onedb-yellow-80: #8E4A06;
$onedb-yellow-90: #6E3606;
$onedb-yellow-100: #4C2609;

$onedb-green-primary: #029C54;
$onedb-green-10: #E4FDED;
$onedb-green-20: #92F7BA;
$onedb-green-30: #41E287;
$onedb-green-40: #15D36E;
$onedb-green-50: #05BE62;
$onedb-green-60: #029C54;
$onedb-green-70: #007744;
$onedb-green-80: #04633D;
$onedb-green-90: #054D33;
$onedb-green-100: #07432F;

$onedb-lime-primary: #A4BB0B;
$onedb-lime-10: #F9FDDE;
$onedb-lime-20: #F1F49B;
$onedb-lime-30: #E0E86A;
$onedb-lime-40: #C7D540;
$onedb-lime-50: #A4BB0B;
$onedb-lime-60: #8BA702;
$onedb-lime-70: #667F00;
$onedb-lime-80: #506901;
$onedb-lime-90: #3A4D04;
$onedb-lime-100: #2E4005;

$onedb-teal-primary: #008E94;
$onedb-teal-10: #D3FBF4;
$onedb-teal-20: #7FEFE0;
$onedb-teal-30: #47DECF;
$onedb-teal-40: #19C7C1;
$onedb-teal-50: #0CB0B0;
$onedb-teal-60: #008E94;
$onedb-teal-70: #037780;
$onedb-teal-80: #035F69;
$onedb-teal-90: #034752;
$onedb-teal-100: #033B46;

$onedb-indigo-10: #EDF5FF;
$onedb-indigo-20: #C8DFFF;
$onedb-indigo-30: #A9C7FC;
$onedb-indigo-40: #789DF4;
$onedb-indigo-50: #5C6FCB;
$onedb-indigo-60: #4C5AB7;
$onedb-indigo-70: #4D4DB9;
$onedb-indigo-80: #44339F;
$onedb-indigo-90: #362284;
$onedb-indigo-100: #2A1A6A;

$onedb-purple-primary: #6A2E99;
$onedb-purple-10: #F9EEFF;
$onedb-purple-20: #F0D3FF;
$onedb-purple-30: #E4B6FF;
$onedb-purple-40: #D694F9;
$onedb-purple-50: #B476DD;
$onedb-purple-60: #9153BB;
$onedb-purple-70: #7E40AC;
$onedb-purple-80: #6A2E99;
$onedb-purple-90: #561A87;
$onedb-purple-100: #460A7A;

$onedb-pink-10: #FFECFC;
$onedb-pink-20: #FFCBF6;
$onedb-pink-30: #FFADEF;
$onedb-pink-40: #FF7DE5;
$onedb-pink-50: #E753BF;
$onedb-pink-60: #C8329F;
$onedb-pink-70: #AA157D;
$onedb-pink-80: #8F0465;
$onedb-pink-90: #71054D;
$onedb-pink-100: #510736;

// Color map
$onedb-colors: (
  blue: (
    primary: $onedb-blue-primary,
    10: $onedb-blue-10,
    20: $onedb-blue-20,
    30: $onedb-blue-30,
    40: $onedb-blue-40,
    50: $onedb-blue-50,
    60: $onedb-blue-60,
    70: $onedb-blue-70,
    80: $onedb-blue-80,
    90: $onedb-blue-90,
    100: $onedb-blue-100
  ),
  black: (
    primary: $onedb-black-primary,
  ),
  white: (
    primary: $onedb-white-primary,
  ),
  gray: (
    primary: $onedb-gray-primary,
    10: $onedb-gray-10,
    15: $onedb-gray-15,
    20: $onedb-gray-20,
    30: $onedb-gray-30,
    40: $onedb-gray-40,
    50: $onedb-gray-50,
    60: $onedb-gray-60,
    70: $onedb-gray-70,
    80: $onedb-gray-80,
    90: $onedb-gray-90,
    100: $onedb-gray-100,
    110: $onedb-gray-110
  ),
  light-gray: (
    primary: $onedb-light-gray-primary
  ),
  cool-gray: (
    10: $onedb-cool-gray-10,
    15: $onedb-cool-gray-15,
    20: $onedb-cool-gray-20,
    30: $onedb-cool-gray-30,
    40: $onedb-cool-gray-40,
    50: $onedb-cool-gray-50,
    60: $onedb-cool-gray-60,
    70: $onedb-cool-gray-70,
    80: $onedb-cool-gray-80,
    90: $onedb-cool-gray-90,
    100: $onedb-cool-gray-100,
    110: $onedb-cool-gray-110
  ),
  red: (
    primary: $onedb-red-primary,
    10: $onedb-red-10,
    20: $onedb-red-20,
    30: $onedb-red-30,
    40: $onedb-red-40,
    50: $onedb-red-50,
    60: $onedb-red-60,
    70: $onedb-red-70,
    80: $onedb-red-80,
    90: $onedb-red-90,
    100: $onedb-red-100
  ),
  orange: (
    primary: $onedb-orange-50,
    10: $onedb-orange-10,
    20: $onedb-orange-20,
    30: $onedb-orange-30,
    40: $onedb-orange-40,
    50: $onedb-orange-50,
    60: $onedb-orange-60,
    70: $onedb-orange-70,
    80: $onedb-orange-80,
    90: $onedb-orange-90,
    100: $onedb-orange-100
  ),
  yellow: (
    primary: $onedb-yellow-primary,
    10: $onedb-yellow-10,
    20: $onedb-yellow-20,
    30: $onedb-yellow-30,
    40: $onedb-yellow-40,
    50: $onedb-yellow-50,
    60: $onedb-yellow-60,
    70: $onedb-yellow-70,
    80: $onedb-yellow-80,
    90: $onedb-yellow-90,
    100: $onedb-yellow-100
  ),
  green: (
    primary: $onedb-green-primary,
    10: $onedb-green-10,
    20: $onedb-green-20,
    30: $onedb-green-30,
    40: $onedb-green-40,
    50: $onedb-green-50,
    60: $onedb-green-60,
    70: $onedb-green-70,
    80: $onedb-green-80,
    90: $onedb-green-90,
    100: $onedb-green-100
  ),
  lime: (
    primary: $onedb-lime-primary,
    10: $onedb-lime-10,
    20: $onedb-lime-20,
    30: $onedb-lime-30,
    40: $onedb-lime-40,
    50: $onedb-lime-50,
    60: $onedb-lime-60,
    70: $onedb-lime-70,
    80: $onedb-lime-80,
    90: $onedb-lime-90,
    100: $onedb-lime-100
  ),
  teal: (
    primary: $onedb-teal-primary,
    10: $onedb-teal-10,
    20: $onedb-teal-20,
    30: $onedb-teal-30,
    40: $onedb-teal-40,
    50: $onedb-teal-50,
    60: $onedb-teal-60,
    70: $onedb-teal-70,
    80: $onedb-teal-80,
    90: $onedb-teal-90,
    100: $onedb-teal-100
  ),
  purple: (
    primary: $onedb-purple-primary,
    10: $onedb-purple-10,
    20: $onedb-purple-20,
    30: $onedb-purple-30,
    40: $onedb-purple-40,
    50: $onedb-purple-50,
    60: $onedb-purple-60,
    70: $onedb-purple-70,
    80: $onedb-purple-80,
    90: $onedb-purple-90,
    100: $onedb-purple-100
  ),
  indigo: (
    10: $onedb-indigo-10,
    20: $onedb-indigo-20,
    30: $onedb-indigo-30,
    40: $onedb-indigo-40,
    50: $onedb-indigo-50,
    60: $onedb-indigo-60,
    70: $onedb-indigo-70,
    80: $onedb-indigo-80,
    90: $onedb-indigo-90,
    100: $onedb-indigo-100
  ),
  pink: (
    10: $onedb-pink-10,
    20: $onedb-pink-20,
    30: $onedb-pink-30,
    40: $onedb-pink-40,
    50: $onedb-pink-50,
    60: $onedb-pink-60,
    70: $onedb-pink-70,
    80: $onedb-pink-80,
    90: $onedb-pink-90,
    100: $onedb-pink-100
  )
);

// Override bootstrap's colors with OneDB colors
$red: $onedb-red-primary !default;
$orange: $onedb-orange-primary !default;
$yellow:$onedb-yellow-primary !default;
$green: $onedb-green-primary !default;
$blue: $onedb-blue-primary !default;
$teal: $onedb-teal-primary !default;
$purple: $onedb-purple-primary !default;

$white: $onedb-white-primary !default;
$black: $onedb-black-primary !default;
$gray-100: $onedb-gray-10 !default;
$gray-200: $onedb-gray-10 !default;
$gray-300: $onedb-gray-20 !default;
$gray-400: $onedb-light-gray-primary !default;
$gray-500: $onedb-gray-60 !default;
$gray-600: $onedb-gray-60 !default;
$gray-700: $onedb-gray-70 !default;
$gray-800: $onedb-gray-80 !default;
$gray-900: $onedb-gray-100 !default;

// Font
$font-family-sans-serif: Roboto-Regular, "Helvetica Neue", Arial, sans-serif !default;
$font-size-base: 0.75rem !default;
$font-size-lg: 1rem !default;
$font-size-sm: .7rem !default;
$font-size-xs: .6rem !default;

$h1-font-size: 2rem !default;
$h2-font-size: 1.75rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: 0.75rem !default;

// Tables
$table-cell-padding: .5rem !default;
$table-sm-cell-padding: .2rem !default;

// Forms
$form-check-input-margin-y: .2rem !default;

// Body
$body-bg: $onedb-gray-15 !default;

// Brand colors
$primary: $onedb-blue-primary !default;
$secondary: $white !default;
$success: $onedb-green-60 !default;
$info: $onedb-blue-primary !default;
$warning: $onedb-yellow-primary !default;
$danger: $onedb-red-primary !default;

// Progress bars
$progress-height: 0.6rem;
