/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2023, 2024. All Rights Reserved.
 *******************************************************************************/
@import "./bootstrap_custom.scss";
$themes: (
  light: (

    //informix header
    color-informix-header: $informix-blue-primary,
    color-informix-header-text: $informix-white-primary,


    // white
    color-white: $informix-white-primary,

    //black
    color-black: $informix-gray-100,
    color-black-HQ: $informix-gray-15,

    //blue
    color-blue: $informix-blue-primary,
    color-blue-HQ: $informix-blue-primary,
    color-blue-10: $informix-blue-10,
    color-blue-20: $informix-blue-20,
    color-blue-30: $informix-blue-30,
    color-blue-40: $informix-blue-40,
    color-blue-50: $informix-blue-50,
    color-blue-60: $informix-blue-60,
    color-blue-70: $informix-blue-70,
    color-blue-80: $informix-blue-80,
    color-blue-90: $informix-blue-90,
    color-blue-100: $informix-blue-100,

    //gray
    color-gray: $informix-gray-primary,
    color-light-gray: $informix-gray-primary,
    color-gray-10: $informix-gray-10,
    color-gray-10-HQ: $informix-light-gray-10,
    color-gray-10-HQ1: $informix-light-gray-40,
    color-gray-10-HQ2: $informix-gray-10-HQ2,
    color-gray-15: $informix-gray-15,
    color-side-bar-border: $informix-light-gray-40,
    color-gray-20: $informix-light-gray-40,
    color-gray-20-HQ: $informix-gray-20-HQ,
    color-gray-30: $informix-gray-30,
    color-gray-30-HQ: $informix-gray-30,
    color-gray-40: $informix-gray-40,
    color-gray-50: $informix-gray-50,
    color-gray-60: $informix-gray-60,
    color-gray-70: $informix-gray-70,
    color-gray-70-HQ: $informix-gray-40,
    color-gray-80: $informix-gray-80,
    color-gray-90: $informix-gray-90,
    color-gray-100: $informix-gray-100,
    color-gray-100-HQ1: $informix-gray-100-HQ,
    color-gray-100-HQ: $informix-black-primary,
    color-gray-110: $informix-gray-110,

    //cool gray
    color-cool-gray-10: $informix-cool-gray-10,
    color-cool-gray-15: $informix-cool-gray-15,
    color-cool-gray-20: $informix-cool-gray-20,
    color-cool-gray-30: $informix-cool-gray-30,
    color-cool-gray-40: $informix-cool-gray-40,
    color-cool-gray-50: $informix-cool-gray-50,
    color-cool-gray-60: $informix-cool-gray-60,
    color-cool-gray-70: $informix-cool-gray-70,
    color-cool-gray-80: $informix-cool-gray-80,
    color-cool-gray-90: $informix-cool-gray-90,
    color-cool-gray-100: $informix-cool-gray-100,
    color-cool-gray-110: $informix-cool-gray-110,


    //color light gray
    color-light-gray-10: $informix-light-gray-10,
    color-light-gray-20: $informix-light-gray-20,
    color-light-gray-30: $informix-light-gray-30,
    color-light-gray-40: $informix-light-gray-40,
    color-light-gray-50: $informix-light-gray-50,
    color-light-gray-60: $informix-light-gray-60,
    color-light-gray-70: $informix-light-gray-70,
    color-light-gray-80: $informix-light-gray-80,


    //red
    color-red: $informix-red-primary,
    color-red-10: $informix-red-10,
    color-red-20: $informix-red-20,
    color-red-30: $informix-red-30,
    color-red-40: $informix-red-40,
    color-red-50: $informix-red-50,
    color-red-60: $informix-red-60,
    color-red-70: $informix-red-70,
    color-red-80: $informix-red-80,
    color-red-90: $informix-red-90,
    color-red-100: $informix-red-100,


    //ruby
    color-ruby: $informix-ruby-primary,
    color-ruby-10: $informix-ruby-10,
    color-ruby-20: $informix-ruby-20,
    color-ruby-30: $informix-ruby-30,
    color-ruby-40: $informix-ruby-40,
    color-ruby-50: $informix-ruby-50,
    color-ruby-60: $informix-ruby-60,
    color-ruby-70: $informix-ruby-70,
    color-ruby-80: $informix-ruby-80,
    color-ruby-90: $informix-ruby-90,

    //orange
    color-orange: $informix-orange-primary,
    color-orange-10: $informix-orange-10,
    color-orange-20: $informix-orange-20,
    color-orange-30: $informix-orange-30,
    color-orange-40: $informix-orange-40,
    color-orange-50: $informix-orange-50,
    color-orange-60: $informix-orange-60,
    color-orange-70: $informix-orange-70,
    color-orange-80: $informix-orange-80,
    color-orange-90: $informix-orange-90,
    color-orange-100: $informix-orange-100,

    //yellow
    color-yellow: $informix-yellow-primary,
    color-yellow-10: $informix-yellow-10,
    color-yellow-20: $informix-yellow-20,
    color-yellow-30: $informix-yellow-30,
    color-yellow-40: $informix-yellow-40,
    color-yellow-50: $informix-yellow-50,
    color-yellow-60: $informix-yellow-60,
    color-yellow-70: $informix-yellow-70,
    color-yellow-80: $informix-yellow-80,
    color-yellow-90: $informix-yellow-90,
    color-yellow-100: $informix-yellow-100,

    //gold
    color-gold: $informix-gold-primary,
    color-gold-10: $informix-gold-10,
    color-gold-20: $informix-gold-20,
    color-gold-30: $informix-gold-30,
    color-gold-40: $informix-gold-40,
    color-gold-50: $informix-gold-50,
    color-gold-60: $informix-gold-60,
    color-gold-70: $informix-gold-70,
    color-gold-80: $informix-gold-80,
    color-gold-90: $informix-gold-90,

    //green
    color-green: $informix-green-primary,
    color-green-10: $informix-green-10,
    color-green-20: $informix-green-20,
    color-green-30: $informix-green-30,
    color-green-40: $informix-green-40,
    color-green-50: $informix-green-50,
    color-green-60: $informix-green-60,
    color-green-70: $informix-green-70,
    color-green-80: $informix-green-80,
    color-green-90: $informix-green-90,
    color-green-100: $informix-green-100,

    //lime
    color-lime: $informix-lime-50,
    color-lime-10: $informix-lime-10,
    color-lime-20: $informix-lime-20,
    color-lime-30: $informix-lime-30,
    color-lime-40: $informix-lime-40,
    color-lime-50: $informix-lime-50,
    color-lime-60: $informix-lime-60,
    color-lime-70: $informix-lime-70,
    color-lime-80: $informix-lime-80,
    color-lime-90: $informix-lime-90,
    color-lime-100: $informix-lime-100,

    //teal
    color-teal: $informix-teal-primary,
    color-teal-10: $informix-teal-10,
    color-teal-20: $informix-teal-20,
    color-teal-30: $informix-teal-30,
    color-teal-40: $informix-teal-40,
    color-teal-50: $informix-teal-50,
    color-teal-60: $informix-teal-60,
    color-teal-70: $informix-teal-70,
    color-teal-80: $informix-teal-80,
    color-teal-90: $informix-teal-90,
    color-teal-100: $informix-teal-100,

    //aqua
    color-aqua: $informix-aqua-primary,
    color-aqua-10: $informix-aqua-10,
    color-aqua-20: $informix-aqua-20,
    color-aqua-30: $informix-aqua-30,
    color-aqua-40: $informix-aqua-40,
    color-aqua-50: $informix-aqua-50,
    color-aqua-60: $informix-aqua-60,
    color-aqua-70: $informix-aqua-70,
    color-aqua-80: $informix-aqua-80,
    color-aqua-90: $informix-aqua-90,

    //violet
    color-violet: $informix-violet-primary,
    color-violet-10: $informix-violet-10,
    color-violet-20: $informix-violet-20,
    color-violet-30: $informix-violet-30,
    color-violet-40: $informix-violet-40,
    color-violet-50: $informix-violet-50,
    color-violet-60: $informix-violet-60,
    color-violet-70: $informix-violet-70,
    color-violet-80: $informix-violet-80,
    color-violet-90: $informix-violet-90,

    //purple
    color-purple: $informix-purple-primary,
    color-purple-10: $informix-purple-10,
    color-purple-20: $informix-purple-20,
    color-purple-30: $informix-purple-30,
    color-purple-40: $informix-purple-40,
    color-purple-50: $informix-purple-50,
    color-purple-60: $informix-purple-60,
    color-purple-70: $informix-purple-70,
    color-purple-80: $informix-purple-80,
    color-purple-90: $informix-purple-90,
    color-purple-100: $informix-purple-100,

    //indigo
    color-indigo-10: $informix-indigo-10,
    color-indigo-20: $informix-indigo-20,
    color-indigo-30: $informix-indigo-30,
    color-indigo-40: $informix-indigo-40,
    color-indigo-50: $informix-indigo-50,
    color-indigo-60: $informix-indigo-60,
    color-indigo-70: $informix-indigo-70,
    color-indigo-80: $informix-indigo-80,
    color-indigo-90: $informix-indigo-90,
    color-indigo-100: $informix-indigo-100,

    //pink
    color-pink-10: $informix-pink-10,
    color-pink-20: $informix-pink-20,
    color-pink-30: $informix-pink-30,
    color-pink-40: $informix-pink-40,
    color-pink-50: $informix-pink-50,
    color-pink-60: $informix-pink-60,
    color-pink-70: $informix-pink-70,
    color-pink-80: $informix-pink-80,
    color-pink-90: $informix-pink-90,
    color-pink-100: $informix-pink-100,

    //others
    color-input-border: $informix-light-gray-60,
    color-input-border-HQ: $informix-light-gray-primary,
    color-input-text: $informix-gray-70,
    color-input-text-HQ1: $informix-gray-40,
    color-input-text-HQ: $informix-gray-70,
    color-input-text-disabled: $informix-gray-40,
    color-input-text-disabled-HQ: $informix-gray-70,
    color-label: $informix-black-primary,
    color-table-text: $informix-gray-100,
    color-table-text-HQ: $informix-gray-100,
    color-table-hover: $informix-light-gray-40,
    color-table-hover-HQ: $informix-gray-20-HQ,
    color-icon: $informix-gray-50,
    color-top-nav: $informix-blue-primary,
    color-nav-link: $informix-gray-50,
    color-nav-link-HQ: $informix-light-gray-70,
    color-nav-link-HQ1: $informix-light-gray-70,
    color-anchor-hover: $informix-blue-90,
    color-btn-hover: $informix-blue-90,
    color-sidebar-text-active: $informix-gray-60,
    color-sidebar-text-active-HQ: $informix-gray-20,
    color_cross_icon: $informix-gray-100,
    color-btn-disabled: $informix-white-primary,
    color-btn-disabled-HQ: $informix-white-primary,
    color-btn-toggle-bg: $informix-blue-10,
    color-btn-toggle-bg-HQ: $informix-aqua-10,
    color-datepicker-shadow: $informix-gray-40,
    color-date: $informix-blue-100,
    color-warning-bg: $informix-white-primary,
    color-warning-border: $informix-yellow-50,
    color-warning-border-HQ: $informix-gold-primary,
    color-warning-icon: $informix-yellow-50,
    color-warning-text: $informix-yellow-80,
    color-warning-text-HQ: $informix-black-primary,
    color-info-bg: $informix-white-primary,
    color-info-border: $informix-blue-60,
    color-info-border-HQ: $informix-blue-50,
    color-info-icon: $informix-blue-60,
    color-info-text: $informix-blue-90,
    color-info-text-HQ: $informix-black-primary,
    color-success-bg: $informix-green-10,
    color-success-border: $informix-green-70,
    color-success-border-HQ: $informix-green-30,
    color-success-icon: $informix-green-30,
    color-success-text: $informix-green-80,
    color-success-text-HQ: $informix-black-primary,
    color-danger-bg: $informix-white-primary,
    color-danger-border: $informix-red-80,
    color-danger-border-HQ: $informix-red-primary,
    color-danger-icon: $informix-red-80,
    color-danger-text: $informix-red-90,
    color-danger-text-HQ: $informix-black-primary,
    background-disabled: rgba(255, 255, 255, 0.75),
    btn-unselect-bg: $informix-gray-20,
),
  dark: (

    color-informix-header: $informix-gray-100,
    color-informix-header-text: $informix-blue-110,
    // white
    color-white: $informix-gray-100,

    //black
    color-black: $informix-light-gray-20,
    color-black-HQ: $informix-gray-20-HQ,

    //blue
    color-blue: $informix-blue-60,
    color-blue-HQ: $informix-blue-110,
    color-blue-10: $informix-blue-50,
    color-blue-20: $informix-blue-20,
    color-blue-30: $informix-blue-30,
    color-blue-40: $informix-blue-40,
    color-blue-50: $informix-blue-50,
    color-blue-60: $informix-blue-60,
    color-blue-70: $informix-blue-70,
    color-blue-80: $informix-blue-80,
    color-blue-90: $informix-blue-90,
    color-blue-100: $informix-blue-100,

    //gray
    color-gray: $informix-gray-primary,
    color-light-gray: $informix-gray-primary,
    color-gray-10: $informix-gray-80,
    color-gray-10-HQ: $informix-gray-80,
    color-gray-10-HQ1: $informix-gray-80,
    color-gray-10-HQ2: $informix-gray-80,
    color-gray-15: $informix-gray-110,
    color-side-bar-border: $informix-gray-110,
    color-gray-20: $informix-gray-80,
    color-gray-20-HQ: $informix-gray-80,
    color-gray-30: $informix-gray-60,
    color-gray-30-HQ: $informix-gray-60-HQ,
    color-gray-40: $informix-gray-70,
    color-gray-50: $informix-gray-40,
    color-gray-60: $informix-gray-40,
    color-gray-70: $informix-gray-40,
    color-gray-70-HQ: $informix-gray-60-HQ,
    color-gray-80: $informix-gray-30,
    color-gray-90: $informix-gray-20,
    color-gray-100: $informix-gray-15,
    color-gray-100-HQ: $informix-gray-15,
    color-gray-100-HQ1: $informix-gray-15,
    color-gray-110: $informix-gray-15,

    //cool gray
    color-cool-gray-10: $informix-cool-gray-10,
    color-cool-gray-15: $informix-cool-gray-15,
    color-cool-gray-20: $informix-cool-gray-20,
    color-cool-gray-30: $informix-cool-gray-30,
    color-cool-gray-40: $informix-cool-gray-40,
    color-cool-gray-50: $informix-cool-gray-50,
    color-cool-gray-60: $informix-cool-gray-60,
    color-cool-gray-70: $informix-cool-gray-70,
    color-cool-gray-80: $informix-cool-gray-80,
    color-cool-gray-90: $informix-cool-gray-90,
    color-cool-gray-100: $informix-cool-gray-100,
    color-cool-gray-110: $informix-cool-gray-110,

    //color light gray
    color-light-gray-10: $informix-light-gray-80,
    color-light-gray-20: $informix-light-gray-70,
    color-light-gray-30: $informix-light-gray-60,
    color-light-gray-40: $informix-light-gray-50,
    color-light-gray-50: $informix-light-gray-40,
    color-light-gray-60: $informix-light-gray-30,
    color-light-gray-70: $informix-light-gray-20,
    color-light-gray-80: $informix-light-gray-10,

    //red
    color-red: $informix-red-60,
    color-red-10: $informix-red-10,
    color-red-20: $informix-red-20,
    color-red-30: $informix-red-30,
    color-red-40: $informix-red-40,
    color-red-50: $informix-red-50,
    color-red-60: $informix-red-60,
    color-red-70: $informix-red-70,
    color-red-80: $informix-red-80,
    color-red-90: $informix-red-90,
    color-red-100: $informix-red-100,

    //ruby
    color-ruby: $informix-ruby-primary,
    color-ruby-10: $informix-ruby-10,
    color-ruby-20: $informix-ruby-20,
    color-ruby-30: $informix-ruby-30,
    color-ruby-40: $informix-ruby-40,
    color-ruby-50: $informix-ruby-50,
    color-ruby-60: $informix-ruby-60,
    color-ruby-70: $informix-ruby-70,
    color-ruby-80: $informix-ruby-80,
    color-ruby-90: $informix-ruby-90,
    
    //orange
    color-orange: $informix-orange-50,
    color-orange-10: $informix-orange-10,
    color-orange-20: $informix-orange-20,
    color-orange-30: $informix-orange-30,
    color-orange-40: $informix-orange-40,
    color-orange-50: $informix-orange-50,
    color-orange-60: $informix-orange-60,
    color-orange-70: $informix-orange-70,
    color-orange-80: $informix-orange-80,
    color-orange-90: $informix-orange-90,
    color-orange-100: $informix-orange-100,

    //yellow
    color-yellow: $informix-yellow-50,
    color-yellow-10: $informix-yellow-10,
    color-yellow-20: $informix-yellow-20,
    color-yellow-30: $informix-yellow-30,
    color-yellow-40: $informix-yellow-40,
    color-yellow-50: $informix-yellow-50,
    color-yellow-60: $informix-yellow-60,
    color-yellow-70: $informix-yellow-70,
    color-yellow-80: $informix-yellow-80,
    color-yellow-90: $informix-yellow-90,
    color-yellow-100: $informix-yellow-100,

    //gold
    color-gold: $informix-gold-primary,
    color-gold-10: $informix-gold-10,
    color-gold-20: $informix-gold-20,
    color-gold-30: $informix-gold-30,
    color-gold-40: $informix-gold-40,
    color-gold-50: $informix-gold-50,
    color-gold-60: $informix-gold-60,
    color-gold-70: $informix-gold-70,
    color-gold-80: $informix-gold-80,
    color-gold-90: $informix-gold-90,

    //green
    color-green: $informix-green-primary,
    color-green-10: $informix-green-10,
    color-green-20: $informix-green-20,
    color-green-30: $informix-green-30,
    color-green-40: $informix-green-40,
    color-green-50: $informix-green-50,
    color-green-60: $informix-green-60,
    color-green-70: $informix-green-70,
    color-green-80: $informix-green-80,
    color-green-90: $informix-green-90,
    color-green-100: $informix-green-100,

    //lime
    color-lime: $informix-lime-50,
    color-lime-10: $informix-lime-10,
    color-lime-20: $informix-lime-20,
    color-lime-30: $informix-lime-30,
    color-lime-40: $informix-lime-40,
    color-lime-50: $informix-lime-50,
    color-lime-60: $informix-lime-60,
    color-lime-70: $informix-lime-70,
    color-lime-80: $informix-lime-80,
    color-lime-90: $informix-lime-90,
    color-lime-100: $informix-lime-100,

    //teal
    color-teal: $informix-teal-primary,
    color-teal-10: $informix-teal-10,
    color-teal-20: $informix-teal-20,
    color-teal-30: $informix-teal-30,
    color-teal-40: $informix-teal-40,
    color-teal-50: $informix-teal-50,
    color-teal-60: $informix-teal-60,
    color-teal-70: $informix-teal-70,
    color-teal-80: $informix-teal-80,
    color-teal-90: $informix-teal-90,
    color-teal-100: $informix-teal-100,

    //aqua
    color-aqua: $informix-aqua-primary,
    color-aqua-10: $informix-aqua-10,
    color-aqua-20: $informix-aqua-20,
    color-aqua-30: $informix-aqua-30,
    color-aqua-40: $informix-aqua-40,
    color-aqua-50: $informix-aqua-50,
    color-aqua-60: $informix-aqua-60,
    color-aqua-70: $informix-aqua-70,
    color-aqua-80: $informix-aqua-80,
    color-aqua-90: $informix-aqua-90,

    //violet
    color-violet: $informix-violet-primary,
    color-violet-10: $informix-violet-10,
    color-violet-20: $informix-violet-20,
    color-violet-30: $informix-violet-30,
    color-violet-40: $informix-violet-40,
    color-violet-50: $informix-violet-50,
    color-violet-60: $informix-violet-60,
    color-violet-70: $informix-violet-70,
    color-violet-80: $informix-violet-80,
    color-violet-90: $informix-violet-90,
    
    //purple
    color-purple: $informix-purple-primary,
    color-purple-10: $informix-purple-10,
    color-purple-20: $informix-purple-20,
    color-purple-30: $informix-purple-30,
    color-purple-40: $informix-purple-40,
    color-purple-50: $informix-purple-50,
    color-purple-60: $informix-purple-60,
    color-purple-70: $informix-purple-70,
    color-purple-80: $informix-purple-80,
    color-purple-90: $informix-purple-90,
    color-purple-100: $informix-purple-100,

    //indigo
    color-indigo-10: $informix-indigo-10,
    color-indigo-20: $informix-indigo-20,
    color-indigo-30: $informix-indigo-30,
    color-indigo-40: $informix-indigo-40,
    color-indigo-50: $informix-indigo-50,
    color-indigo-60: $informix-indigo-60,
    color-indigo-70: $informix-indigo-70,
    color-indigo-80: $informix-indigo-80,
    color-indigo-90: $informix-indigo-90,
    color-indigo-100: $informix-indigo-100,

    //pink
    color-pink-10: $informix-pink-10,
    color-pink-20: $informix-pink-20,
    color-pink-30: $informix-pink-30,
    color-pink-40: $informix-pink-40,
    color-pink-50: $informix-pink-50,
    color-pink-60: $informix-pink-60,
    color-pink-70: $informix-pink-70,
    color-pink-80: $informix-pink-80,
    color-pink-90: $informix-pink-90,
    color-pink-100: $informix-pink-100,

    //others
    color-input-border: $informix-gray-40,
    color-input-border-HQ: $informix-gray-70-HQ,
    color-input-text: $informix-gray-20,
    color-input-text-HQ1: $informix-gray-20-HQ,
    color-input-text-HQ: $informix-gray-20,
    color-input-text-disabled: $informix-gray-10,
    color-input-text-disabled-HQ: $informix-gray-50-HQ,
    color-label: $informix-gray-20-HQ,
    color-table-text: $informix-gray-20,
    color-table-text-HQ: $informix-gray-20-HQ,
    color-table-hover: $informix-gray-20,
    color-table-hover-HQ: $informix-gray-70-HQ,
    color-icon: $informix-gray-20,
    color-top-nav: $informix-gray-80,
    color-nav-link: $informix-gray-50,
    color-nav-link-HQ: $informix-gray-50-HQ,
    color-nav-link-HQ1: $informix-gray-20-HQ,
    color-anchor-hover: $informix-blue-70-HQ,
    color-btn-hover: $informix-blue-60,
    color-sidebar-text-active: $informix-gray-30,
    color-sidebar-text-active-HQ: $informix-gray-30-HQ,
    color_cross_icon: $informix-gray-20,
    color-btn-disabled: $informix-gray-30,
    color-btn-disabled-HQ: $informix-gray-30-HQ,
    color-btn-toggle-bg: $informix-blue-100,
    color-btn-toggle-bg-HQ: $informix-blue-80,
    color-datepicker-shadow: $informix-gray-90,
    color-date: $informix-blue-60,
    color-warning-bg: $informix-orange-80,
    color-warning-border:$informix-gold-50,
    color-warning-border-HQ:$informix-gold-50,
    color-warning-icon:$informix-gold-50,
    color-warning-text: $informix-yellow-20,
    color-warning-text-HQ: $informix-yellow-20,
    color-info-bg: $informix-blue-80,
    color-info-border: $informix-blue-50,
    color-info-border-HQ: $informix-blue-50,
    color-info-icon: $informix-blue-60,
    color-info-text: $informix-blue-10,
    color-info-text-HQ: $informix-blue-10,
    color-success-bg: $informix-green-100,
    color-success-border: $informix-green-60,
    color-success-border-HQ: $informix-green-60,
    color-success-icon: $informix-green-60,
    color-success-text: $informix-green-20,
    color-success-text-HQ: $informix-green-20,
    color-danger-bg: $informix-red-100,
    color-danger-border: $informix-red-60,
    color-danger-border-HQ: $informix-red-60,
    color-danger-icon: $informix-red-60,
    color-danger-text: $informix-red-30,
    color-danger-text-HQ: $informix-red-30-HQ,
    background-disabled: rgba(38, 38, 38, 0.80),
    btn-unselect-bg: $informix-gray-40
  )
);

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {//figure out submap
        $value: map-get(map-get($themes, $theme), '#{$key}');// value based on theme
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
