$icomoon-font-family: "ihq-icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-activity: unquote('"\\e902"');
$icon-administrator: unquote('"\\e903"');
$icon-announcement: unquote('"\\e904"');
$icon-api: unquote('"\\e905"');
$icon-application: unquote('"\\e906"');
$icon-archive: unquote('"\\e901"');
$icon-arrow-down: unquote('"\\e907"');
$icon-arrow-left: unquote('"\\e908"');
$icon-arrow-left-circle: unquote('"\\e909"');
$icon-arrow-right: unquote('"\\e90a"');
$icon-arrow-right-circle: unquote('"\\e90b"');
$icon-arrow-up: unquote('"\\e90c"');
$icon-attachment: unquote('"\\e90d"');
$icon-audio: unquote('"\\e90e"');
$icon-audio-file: unquote('"\\e90f"');
$icon-ban: unquote('"\\e910"');
$icon-bar-chart: unquote('"\\e911"');
$icon-bare-metal-server: unquote('"\\e912"');
$icon-bars: unquote('"\\e913"');
$icon-bars-vertical: unquote('"\\e914"');
$icon-bell: unquote('"\\e915"');
$icon-blog: unquote('"\\e916"');
$icon-bluetooth: unquote('"\\e917"');
$icon-bold: unquote('"\\e918"');
$icon-bolt: unquote('"\\e919"');
$icon-bookmark: unquote('"\\e91a"');
$icon-bulleted-list: unquote('"\\e91b"');
$icon-calendar: unquote('"\\e91c"');
$icon-catalog: unquote('"\\e91d"');
$icon-center-align: unquote('"\\e91e"');
$icon-chart-bar: unquote('"\\e91f"');
$icon-chart-line: unquote('"\\e920"');
$icon-chart-pie: unquote('"\\e921"');
$icon-chart-table: unquote('"\\e922"');
$icon-chat-message: unquote('"\\e923"');
$icon-check: unquote('"\\e924"');
$icon-check-circle: unquote('"\\e925"');
$icon-checklist: unquote('"\\e926"');
$icon-check-off: unquote('"\\e927"');
$icon-check-on: unquote('"\\e928"');
$icon-checkout: unquote('"\\e929"');
$icon-check-thin: unquote('"\\e92a"');
$icon-chevron-down: unquote('"\\e92b"');
$icon-chevron-left: unquote('"\\e92c"');
$icon-chevron-right: unquote('"\\e92d"');
$icon-chevron-up: unquote('"\\e92e"');
$icon-child-relationship: unquote('"\\e92f"');
$icon-chunk: unquote('"\\e930"');
$icon-circle: unquote('"\\e931"');
$icon-circle-notch: unquote('"\\e932"');
$icon-clear-all: unquote('"\\e933"');
$icon-client: unquote('"\\e934"');
$icon-clipboard: unquote('"\\e935"');
$icon-clipboard-check: unquote('"\\e936"');
$icon-clock: unquote('"\\e937"');
$icon-cloud: unquote('"\\e938"');
$icon-cloud-done: unquote('"\\e939"');
$icon-cloud-download: unquote('"\\e93a"');
$icon-cloud-upload: unquote('"\\e93b"');
$icon-code: unquote('"\\e93c"');
$icon-compass: unquote('"\\e93d"');
$icon-compress: unquote('"\\e93e"');
$icon-configure: unquote('"\\e93f"');
$icon-copy: unquote('"\\e940"');
$icon-cut: unquote('"\\e941"');
$icon-dashboard: unquote('"\\e942"');
$icon-data: unquote('"\\e943"');
$icon-database: unquote('"\\e944"');
$icon-data-quality: unquote('"\\e945"');
$icon-data-set: unquote('"\\e946"');
$icon-debug: unquote('"\\e947"');
$icon-delete: unquote('"\\e948"');
$icon-delete-history: unquote('"\\e949"');
$icon-deploy: unquote('"\\e94a"');
$icon-desktop: unquote('"\\e94b"');
$icon-dislike: unquote('"\\e94c"');
$icon-document: unquote('"\\e94d"');
$icon-download: unquote('"\\e94e"');
$icon-down-to-bottom: unquote('"\\e94f"');
$icon-edit: unquote('"\\e950"');
$icon-email: unquote('"\\e951"');
$icon-er-replicate-tree-icon: unquote('"\\e952"');
$icon-exclamation-badge: unquote('"\\e953"');
$icon-exclamation-circle: unquote('"\\e954"');
$icon-export-icon: unquote('"\\e955"');
$icon-extend: unquote('"\\e956"');
$icon-eye: unquote('"\\e957"');
$icon-eye-blocked: unquote('"\\e958"');
$icon-file: unquote('"\\e959"');
$icon-filter: unquote('"\\e95a"');
$icon-flow: unquote('"\\e95b"');
$icon-folder: unquote('"\\e95c"');
$icon-folder-open: unquote('"\\e95d"');
$icon-fullScreen: unquote('"\\e95e"');
$icon-full-screen: unquote('"\\e95f"');
$icon-full-screen-exit: unquote('"\\e960"');
$icon-glasses: unquote('"\\e961"');
$icon-group: unquote('"\\e962"');
$icon-hat: unquote('"\\e963"');
$icon-hdr-server: unquote('"\\e964"');
$icon-help: unquote('"\\e965"');
$icon-hide: unquote('"\\e966"');
$icon-home: unquote('"\\e967"');
$icon-idea: unquote('"\\e968"');
$icon-image: unquote('"\\e969"');
$icon-indent: unquote('"\\e96a"');
$icon-info: unquote('"\\e96b"');
$icon-info-circle: unquote('"\\e96c"');
$icon-insecure: unquote('"\\e96d"');
$icon-insert: unquote('"\\e96e"');
$icon-italic: unquote('"\\e96f"');
$icon-justify: unquote('"\\e970"');
$icon-left-align: unquote('"\\e971"');
$icon-like: unquote('"\\e972"');
$icon-link: unquote('"\\e973"');
$icon-load-query-icon: unquote('"\\e900"');
$icon-location: unquote('"\\e974"');
$icon-lock: unquote('"\\e975"');
$icon-manage: unquote('"\\e976"');
$icon-map: unquote('"\\e977"');
$icon-maximize: unquote('"\\e978"');
$icon-menu-overflow-horizontal: unquote('"\\e979"');
$icon-menu-overflow-vertical: unquote('"\\e97a"');
$icon-migrate: unquote('"\\e97b"');
$icon-minimize: unquote('"\\e97c"');
$icon-minus: unquote('"\\e97d"');
$icon-minus-circle: unquote('"\\e97e"');
$icon-mobile: unquote('"\\e97f"');
$icon-monitor: unquote('"\\e980"');
$icon-more: unquote('"\\e981"');
$icon-move: unquote('"\\e982"');
$icon-newsletter: unquote('"\\e983"');
$icon-notification-error: unquote('"\\e984"');
$icon-numbered-list: unquote('"\\e985"');
$icon-outdent: unquote('"\\e986"');
$icon-overview: unquote('"\\e987"');
$icon-pages: unquote('"\\e988"');
$icon-parameter: unquote('"\\e989"');
$icon-password-key: unquote('"\\e98a"');
$icon-paste: unquote('"\\e98b"');
$icon-pause: unquote('"\\e98c"');
$icon-payment-card: unquote('"\\e98d"');
$icon-phone: unquote('"\\e98e"');
$icon-pie-chart: unquote('"\\e98f"');
$icon-play: unquote('"\\e990"');
$icon-plus: unquote('"\\e991"');
$icon-plus-circle: unquote('"\\e992"');
$icon-plus-square: unquote('"\\e993"');
$icon-policy: unquote('"\\e994"');
$icon-power: unquote('"\\e995"');
$icon-power-down: unquote('"\\e996"');
$icon-primary-server: unquote('"\\e997"');
$icon-print: unquote('"\\e998"');
$icon-project: unquote('"\\e999"');
$icon-question: unquote('"\\e99a"');
$icon-question-circle: unquote('"\\e99b"');
$icon-radio-off: unquote('"\\e99c"');
$icon-radio-on: unquote('"\\e99d"');
$icon-recording: unquote('"\\e99e"');
$icon-refresh: unquote('"\\e99f"');
$icon-rename: unquote('"\\e9a0"');
$icon-report: unquote('"\\e9a1"');
$icon-reset: unquote('"\\e9a2"');
$icon-restore: unquote('"\\e9a3"');
$icon-resume: unquote('"\\e9a4"');
$icon-retrieve: unquote('"\\e9a5"');
$icon-right-align: unquote('"\\e9a6"');
$icon-rss: unquote('"\\e9a7"');
$icon-rss-server: unquote('"\\e9a8"');
$icon-run: unquote('"\\e9a9"');
$icon-save: unquote('"\\e9aa"');
$icon-sds-server: unquote('"\\e9ab"');
$icon-search: unquote('"\\e9ac"');
$icon-secure: unquote('"\\e9ad"');
$icon-server: unquote('"\\e9ae"');
$icon-server-connection: unquote('"\\e9af"');
$icon-settings: unquote('"\\e9b0"');
$icon-sort: unquote('"\\e9b1"');
$icon-sort-asc: unquote('"\\e9b2"');
$icon-sort-desc: unquote('"\\e9b3"');
$icon-spell-check: unquote('"\\e9b4"');
$icon-star: unquote('"\\e9b5"');
$icon-star-half: unquote('"\\e9b6"');
$icon-star-outline: unquote('"\\e9b7"');
$icon-start: unquote('"\\e9b8"');
$icon-stop: unquote('"\\e9b9"');
$icon-stop-square: unquote('"\\e9ba"');
$icon-structure: unquote('"\\e9bb"');
$icon-suspend: unquote('"\\e9bc"');
$icon-swap-horizontal: unquote('"\\e9bd"');
$icon-swap-vertical: unquote('"\\e9be"');
$icon-table: unquote('"\\e9bf"');
$icon-table-cell: unquote('"\\e9c0"');
$icon-table-column: unquote('"\\e9c1"');
$icon-table-row: unquote('"\\e9c2"');
$icon-tables: unquote('"\\e9c3"');
$icon-tag: unquote('"\\e9c4"');
$icon-template: unquote('"\\e9c5"');
$icon-terminal: unquote('"\\e9c6"');
$icon-text-color: unquote('"\\e9c7"');
$icon-three-d: unquote('"\\e9c8"');
$icon-tile-view: unquote('"\\e9c9"');
$icon-times: unquote('"\\e9ca"');
$icon-times-circle: unquote('"\\e9cb"');
$icon-times-thin: unquote('"\\e9cc"');
$icon-tools: unquote('"\\e9cd"');
$icon-trash: unquote('"\\e9ce"');
$icon-underline: unquote('"\\e9cf"');
$icon-unlock: unquote('"\\e9d0"');
$icon-unstructure: unquote('"\\e9d1"');
$icon-upload: unquote('"\\e9d2"');
$icon-up-to: unquote('"\\e9d3"');
$icon-usb: unquote('"\\e9d4"');
$icon-user: unquote('"\\e9d5"');
$icon-video: unquote('"\\e9d6"');
$icon-view: unquote('"\\e9d7"');
$icon-wallet: unquote('"\\e9d8"');
$icon-warning: unquote('"\\e9d9"');
$icon-workspace: unquote('"\\e9da"');
$icon-world: unquote('"\\e9db"');
$icon-zoom-in: unquote('"\\e9dc"');
$icon-zoom-out: unquote('"\\e9dd"');

