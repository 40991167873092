@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hegipv');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hegipv#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hegipv') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hegipv') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hegipv##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-activity {
  &:before {
    content: $icon-activity; 
  }
}
.icon-administrator {
  &:before {
    content: $icon-administrator; 
  }
}
.icon-announcement {
  &:before {
    content: $icon-announcement; 
  }
}
.icon-api {
  &:before {
    content: $icon-api; 
  }
}
.icon-application {
  &:before {
    content: $icon-application; 
  }
}
.icon-archive {
  &:before {
    content: $icon-archive; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-left-circle {
  &:before {
    content: $icon-arrow-left-circle; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-right-circle {
  &:before {
    content: $icon-arrow-right-circle; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-attachment {
  &:before {
    content: $icon-attachment; 
  }
}
.icon-audio {
  &:before {
    content: $icon-audio; 
  }
}
.icon-audio-file {
  &:before {
    content: $icon-audio-file; 
  }
}
.icon-ban {
  &:before {
    content: $icon-ban; 
  }
}
.icon-bar-chart {
  &:before {
    content: $icon-bar-chart; 
  }
}
.icon-bare-metal-server {
  &:before {
    content: $icon-bare-metal-server; 
  }
}
.icon-bars {
  &:before {
    content: $icon-bars; 
  }
}
.icon-bars-vertical {
  &:before {
    content: $icon-bars-vertical; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-blog {
  &:before {
    content: $icon-blog; 
  }
}
.icon-bluetooth {
  &:before {
    content: $icon-bluetooth; 
  }
}
.icon-bold {
  &:before {
    content: $icon-bold; 
  }
}
.icon-bolt {
  &:before {
    content: $icon-bolt; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-bulleted-list {
  &:before {
    content: $icon-bulleted-list; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-catalog {
  &:before {
    content: $icon-catalog; 
  }
}
.icon-center-align {
  &:before {
    content: $icon-center-align; 
  }
}
.icon-chart-bar {
  &:before {
    content: $icon-chart-bar; 
  }
}
.icon-chart-line {
  &:before {
    content: $icon-chart-line; 
  }
}
.icon-chart-pie {
  &:before {
    content: $icon-chart-pie; 
  }
}
.icon-chart-table {
  &:before {
    content: $icon-chart-table; 
  }
}
.icon-chat-message {
  &:before {
    content: $icon-chat-message; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-check-circle {
  &:before {
    content: $icon-check-circle; 
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist; 
  }
}
.icon-check-off {
  &:before {
    content: $icon-check-off; 
  }
}
.icon-check-on {
  &:before {
    content: $icon-check-on; 
  }
}
.icon-checkout {
  &:before {
    content: $icon-checkout; 
  }
}
.icon-check-thin {
  &:before {
    content: $icon-check-thin; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-child-relationship {
  &:before {
    content: $icon-child-relationship; 
  }
}
.icon-chunk {
  &:before {
    content: $icon-chunk; 
  }
}
.icon-circle {
  &:before {
    content: $icon-circle; 
  }
}
.icon-circle-notch {
  &:before {
    content: $icon-circle-notch; 
  }
}
.icon-clear-all {
  &:before {
    content: $icon-clear-all; 
  }
}
.icon-client {
  &:before {
    content: $icon-client; 
  }
}
.icon-clipboard {
  &:before {
    content: $icon-clipboard; 
  }
}
.icon-clipboard-check {
  &:before {
    content: $icon-clipboard-check; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud; 
  }
}
.icon-cloud-done {
  &:before {
    content: $icon-cloud-done; 
  }
}
.icon-cloud-download {
  &:before {
    content: $icon-cloud-download; 
  }
}
.icon-cloud-upload {
  &:before {
    content: $icon-cloud-upload; 
  }
}
.icon-code {
  &:before {
    content: $icon-code; 
  }
}
.icon-compass {
  &:before {
    content: $icon-compass; 
  }
}
.icon-compress {
  &:before {
    content: $icon-compress; 
  }
}
.icon-configure {
  &:before {
    content: $icon-configure; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-cut {
  &:before {
    content: $icon-cut; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-data {
  &:before {
    content: $icon-data; 
  }
}
.icon-database {
  &:before {
    content: $icon-database; 
  }
}
.icon-data-quality {
  &:before {
    content: $icon-data-quality; 
  }
}
.icon-data-set {
  &:before {
    content: $icon-data-set; 
  }
}
.icon-debug {
  &:before {
    content: $icon-debug; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-delete-history {
  &:before {
    content: $icon-delete-history; 
  }
}
.icon-deploy {
  &:before {
    content: $icon-deploy; 
  }
}
.icon-desktop {
  &:before {
    content: $icon-desktop; 
  }
}
.icon-dislike {
  &:before {
    content: $icon-dislike; 
  }
}
.icon-document {
  &:before {
    content: $icon-document; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-down-to-bottom {
  &:before {
    content: $icon-down-to-bottom; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-er-replicate-tree-icon {
  &:before {
    content: $icon-er-replicate-tree-icon; 
  }
}
.icon-exclamation-badge {
  &:before {
    content: $icon-exclamation-badge; 
  }
}
.icon-exclamation-circle {
  &:before {
    content: $icon-exclamation-circle; 
  }
}
.icon-export-icon {
  &:before {
    content: $icon-export-icon; 
  }
}
.icon-extend {
  &:before {
    content: $icon-extend; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-eye-blocked {
  &:before {
    content: $icon-eye-blocked; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-flow {
  &:before {
    content: $icon-flow; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-fullScreen {
  &:before {
    content: $icon-fullScreen; 
  }
}
.icon-full-screen {
  &:before {
    content: $icon-full-screen; 
  }
}
.icon-full-screen-exit {
  &:before {
    content: $icon-full-screen-exit; 
  }
}
.icon-glasses {
  &:before {
    content: $icon-glasses; 
  }
}
.icon-group {
  &:before {
    content: $icon-group; 
  }
}
.icon-hat {
  &:before {
    content: $icon-hat; 
  }
}
.icon-hdr-server {
  &:before {
    content: $icon-hdr-server; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-hide {
  &:before {
    content: $icon-hide; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-idea {
  &:before {
    content: $icon-idea; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-indent {
  &:before {
    content: $icon-indent; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-info-circle {
  &:before {
    content: $icon-info-circle; 
  }
}
.icon-insecure {
  &:before {
    content: $icon-insecure; 
  }
}
.icon-insert {
  &:before {
    content: $icon-insert; 
  }
}
.icon-italic {
  &:before {
    content: $icon-italic; 
  }
}
.icon-justify {
  &:before {
    content: $icon-justify; 
  }
}
.icon-left-align {
  &:before {
    content: $icon-left-align; 
  }
}
.icon-like {
  &:before {
    content: $icon-like; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-load-query-icon {
  &:before {
    content: $icon-load-query-icon; 
  }
}
.icon-location {
  &:before {
    content: $icon-location; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-manage {
  &:before {
    content: $icon-manage; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-maximize {
  &:before {
    content: $icon-maximize; 
  }
}
.icon-menu-overflow-horizontal {
  &:before {
    content: $icon-menu-overflow-horizontal; 
  }
}
.icon-menu-overflow-vertical {
  &:before {
    content: $icon-menu-overflow-vertical; 
  }
}
.icon-migrate {
  &:before {
    content: $icon-migrate; 
  }
}
.icon-minimize {
  &:before {
    content: $icon-minimize; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-minus-circle {
  &:before {
    content: $icon-minus-circle; 
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile; 
  }
}
.icon-monitor {
  &:before {
    content: $icon-monitor; 
  }
}
.icon-more {
  &:before {
    content: $icon-more; 
  }
}
.icon-move {
  &:before {
    content: $icon-move; 
  }
}
.icon-newsletter {
  &:before {
    content: $icon-newsletter; 
  }
}
.icon-notification-error {
  &:before {
    content: $icon-notification-error; 
  }
}
.icon-numbered-list {
  &:before {
    content: $icon-numbered-list; 
  }
}
.icon-outdent {
  &:before {
    content: $icon-outdent; 
  }
}
.icon-overview {
  &:before {
    content: $icon-overview; 
  }
}
.icon-pages {
  &:before {
    content: $icon-pages; 
  }
}
.icon-parameter {
  &:before {
    content: $icon-parameter; 
  }
}
.icon-password-key {
  &:before {
    content: $icon-password-key; 
  }
}
.icon-paste {
  &:before {
    content: $icon-paste; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-payment-card {
  &:before {
    content: $icon-payment-card; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-pie-chart {
  &:before {
    content: $icon-pie-chart; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-plus-circle {
  &:before {
    content: $icon-plus-circle; 
  }
}
.icon-plus-square {
  &:before {
    content: $icon-plus-square; 
  }
}
.icon-policy {
  &:before {
    content: $icon-policy; 
  }
}
.icon-power {
  &:before {
    content: $icon-power; 
  }
}
.icon-power-down {
  &:before {
    content: $icon-power-down; 
  }
}
.icon-primary-server {
  &:before {
    content: $icon-primary-server; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-project {
  &:before {
    content: $icon-project; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle; 
  }
}
.icon-radio-off {
  &:before {
    content: $icon-radio-off; 
  }
}
.icon-radio-on {
  &:before {
    content: $icon-radio-on; 
  }
}
.icon-recording {
  &:before {
    content: $icon-recording; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-rename {
  &:before {
    content: $icon-rename; 
  }
}
.icon-report {
  &:before {
    content: $icon-report; 
  }
}
.icon-reset {
  &:before {
    content: $icon-reset; 
  }
}
.icon-restore {
  &:before {
    content: $icon-restore; 
  }
}
.icon-resume {
  &:before {
    content: $icon-resume; 
  }
}
.icon-retrieve {
  &:before {
    content: $icon-retrieve; 
  }
}
.icon-right-align {
  &:before {
    content: $icon-right-align; 
  }
}
.icon-rss {
  &:before {
    content: $icon-rss; 
  }
}
.icon-rss-server {
  &:before {
    content: $icon-rss-server; 
  }
}
.icon-run {
  &:before {
    content: $icon-run; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-sds-server {
  &:before {
    content: $icon-sds-server; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-secure {
  &:before {
    content: $icon-secure; 
  }
}
.icon-server {
  &:before {
    content: $icon-server; 
  }
}
.icon-server-connection {
  &:before {
    content: $icon-server-connection; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-sort-asc {
  &:before {
    content: $icon-sort-asc; 
  }
}
.icon-sort-desc {
  &:before {
    content: $icon-sort-desc; 
  }
}
.icon-spell-check {
  &:before {
    content: $icon-spell-check; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-star-half {
  &:before {
    content: $icon-star-half; 
  }
}
.icon-star-outline {
  &:before {
    content: $icon-star-outline; 
  }
}
.icon-start {
  &:before {
    content: $icon-start; 
  }
}
.icon-stop {
  &:before {
    content: $icon-stop; 
  }
}
.icon-stop-square {
  &:before {
    content: $icon-stop-square; 
  }
}
.icon-structure {
  &:before {
    content: $icon-structure; 
  }
}
.icon-suspend {
  &:before {
    content: $icon-suspend; 
  }
}
.icon-swap-horizontal {
  &:before {
    content: $icon-swap-horizontal; 
  }
}
.icon-swap-vertical {
  &:before {
    content: $icon-swap-vertical; 
  }
}
.icon-table {
  &:before {
    content: $icon-table; 
  }
}
.icon-table-cell {
  &:before {
    content: $icon-table-cell; 
  }
}
.icon-table-column {
  &:before {
    content: $icon-table-column; 
  }
}
.icon-table-row {
  &:before {
    content: $icon-table-row; 
  }
}
.icon-tables {
  &:before {
    content: $icon-tables; 
  }
}
.icon-tag {
  &:before {
    content: $icon-tag; 
  }
}
.icon-template {
  &:before {
    content: $icon-template; 
  }
}
.icon-terminal {
  &:before {
    content: $icon-terminal; 
  }
}
.icon-text-color {
  &:before {
    content: $icon-text-color; 
  }
}
.icon-three-d {
  &:before {
    content: $icon-three-d; 
  }
}
.icon-tile-view {
  &:before {
    content: $icon-tile-view; 
  }
}
.icon-times {
  &:before {
    content: $icon-times; 
  }
}
.icon-times-circle {
  &:before {
    content: $icon-times-circle; 
  }
}
.icon-times-thin {
  &:before {
    content: $icon-times-thin; 
  }
}
.icon-tools {
  &:before {
    content: $icon-tools; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-underline {
  &:before {
    content: $icon-underline; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-unstructure {
  &:before {
    content: $icon-unstructure; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-up-to {
  &:before {
    content: $icon-up-to; 
  }
}
.icon-usb {
  &:before {
    content: $icon-usb; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-view {
  &:before {
    content: $icon-view; 
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-workspace {
  &:before {
    content: $icon-workspace; 
  }
}
.icon-world {
  &:before {
    content: $icon-world; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}

